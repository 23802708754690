/*
 * @Author: yuszhou yuszhou@deloitte.com.cn
 * @Date: 2023-02-23 11:24:29
 * @LastEditors: yuszhou yuszhou@deloitte.com.cn
 * @LastEditTime: 2023-03-03 14:38:04
 * @FilePath: \rc_china_storeportal_ma\src\locale\messageCenter\en_US.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  'messageCenter.Consumer Phone Number': 'Consumer Phone Number',
  'messageCenter.Consumer Email': 'Consumer Email',
  'messageCenter.Consumer Account': 'Consumer Account',
  'messageCenter.Consumer Type': 'Consumer Type',
  'messageCenter.Signature': 'Signature',
  'messageCenter.Reciplents': 'Recipients',
  'messageCenter.Message Detail': 'Message Detail',
  'messageCenter.refresh': 'Refresh',
  'messageCenter.Pending': 'Pending',
  'messageCenter.Finish': 'Finish',
  'messageCenter.Task ID': 'Task ID',
  'messageCenter.Template Name': 'Template Name',
  'messageCenter.Push type': 'Push Type',
  'messageCenter.Push Channel': 'Push Channel',
  'messageCenter.Reciplent': 'Recipient',
  'messageCenter.Satus': 'Status',
  'messageCenter.Object Type': 'Object Type',
  'messageCenter.Object No.': 'Object No.',
  'messageCenter.quickSend': 'Quick Send',
  'messageCenter.Options': 'Options',
  'Please input task ID ': 'Please Input Recipient Contact',
  'Task List displays all records that were sent or planning to send to consumers.': 'Message List displays all records that were sent or planning to send to consumers.'
}