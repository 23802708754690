/*
 * @Author: yuszhou yuszhou@deloitte.com.cn
 * @Date: 2023-02-21 13:25:00
 * @LastEditors: yuszhou yuszhou@deloitte.com.cn
 * @LastEditTime: 2023-03-02 20:09:10
 * @FilePath: \rc_china_storeportal_ma\src\locale\smartOperation\en_US.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE,
 * 
 */
export default {
  'smartOperation.Create Task': 'Create Task',
  'smartOperation.Basic Task Information': 'Basic Task Information',
  'smartOperation.Conditions': 'Conditions',
  'smartOperation.Goal': 'Goal',
  'smartOperation.Save as Draft': 'Save as Draft',
  'smartOperation.Task Label': 'Task Label',
  'smartOperation.Task Type': 'Task Type',
  'smartOperation.One Time': 'One Time',
  'smartOperation.Repeated Time': 'Repeated Time',
  'smartOperation.Behaviour': 'Behaviour',
  'smartOperation.The Task will be executed once': 'The Task will be executed once',
  'smartOperation.Triggering Time': 'Triggering Time',
  'smartOperation.Start and End Time': 'Start and End Time',
  'smartOperation.Trigger Events': 'Trigger Events',
  'smartOperation.Add events': 'Add events',
  'smartOperation.Target Users are selected by segments': 'Target Users are selected by segments',
  'smartOperation.Population': 'Population',
  'smartOperation.Target Users': 'Target Users',
  'smartOperation.Push Type': 'Push Type',
  'smartOperation.SMS': 'SMS',
  'smartOperation.Email': 'Email',
  'smartOperation.Push Channel': 'Push Channel',
  'smartOperation.Template Name': 'Template Name',
  'Goal Setting': 'Goal Setting',
  'If the following conversion events are completed within': 'If the following conversion events are completed within ',
  'after the user enters the process, the goal is  considered to be compleeted': 'after the user enters the process, the goal is  considered to be compleeted',
  'Add Secondary Goal': 'Add Secondary Goal',
  'Primary Goal': 'Primary Goal',
  'Second Goal': 'Second Goal',
  'th': ' Th',
  'smartOperation.Operation Log': 'Operation Log',
  'smartOperation.Planned Executed Time': 'Planned Executed Time',
  'smartOperation.Actual Executed Time': 'Actual Executed Time',
  'smartOperation.Update Result': 'Update Result',
  'smartOperation.Target Population': 'Target Population',
  'smartOperation.Actual Population': 'Actual Population',
  'smartOperation.Goal Achieved Population': 'Goal Achieved Population',
  'smartOperation.Operation': 'Operation',
  'smartOperation.Task Description': 'Task Description',
  'smartOperation.update': 'Update',
  'smartOperation.Update Task': 'Edit Task',
  'smartOperation.Detail Task': 'Task Detail',
  'smartOperation.please enter number': 'please enter number',
}