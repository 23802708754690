/*
 * @Author: yuszhou yuszhou@deloitte.com.cn
 * @Date: 2023-02-21 10:51:40
 * @LastEditors: yuszhou yuszhou@deloitte.com.cn
 * @LastEditTime: 2023-02-28 13:01:57
 * @FilePath: \rc_china_storeportal_ma\src\framework\api\fetcher.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ClientBuilder from '@dtc-sellercenter-sdk/fetch/'
// import ClientBuilder from '@/rc-china-commerce/packages/fetch/lib/index'
import history from '@/routers/history'
import { message } from 'antd'

let isShowingError = false

// const API_URL = 'http://localhost:9000/graphql'
// 生产
const API_URL = 'https://api.dev.d2cgo.com/seller-center/graphql'
// const API_URL = 'http://20.62.176.70/faas/graphql'
// const API_URL = 'https://msstg.d2cgo.com/faas/graphql'

// 开发
// const API_URL = 'https://dtc-faas-dev-dtc-plaform-dev-yfetifgpvj.cn-shanghai.fcapp.run/graphql'

// const API_URL = `${process.env.REACT_APP_BASE_URL}/graphql`

export const UPLOAD_API_URL = process.env.REACT_APP_APP_ENV === 'prod' ? 'https://api.d2cgo.com/public/upload' : 'https://api.dev.d2cgo.com/public/upload'

const handleError: (err: string, isNeedToLogin: boolean) => void = (err, isNeedToLogin = false) => {
  if (!isShowingError) {
    err = err == undefined ? 'error' : err
    message.error({ className: 'rc-message', content: err === 'GqlAuthGuard' ? 'Login expired, please login again!' : err, onClose: () => { isShowingError = false } })
    isShowingError = true
  }
  if (isNeedToLogin) {
    sessionStorage.setItem('activeTab', "0")
    history.push('/login')
  }
}

const ApiRoot = ({ url = API_URL, isShowError = true }: { url?: string, isShowError?: boolean } = { url: API_URL, isShowError: true }) => new ClientBuilder().config({
  url: url,
  handleError: handleError
}).config({ url, isShowError: isShowError })

export default ApiRoot
