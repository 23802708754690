import Reset from '@/views/reset'
import ResetSuccess from '@/views/resetSuccess'
import { lazy } from 'react' // 路由懒加载
const ComingSoon = lazy(() => import('@/views/comingSoon'))
const Home = lazy(() => import('../views/index'))
const Login = lazy(() => import('../views/login'))
const LoginStore = lazy(() => import('../views/loginStore'))
const LoginBrand = lazy(() => import('../views/loginBrand'))
const ResetPassword = lazy(() => import('../views/resetPassword'))
const Register = lazy(() => import('../views/register'))
const Mfa = lazy(() => import('../views/Mfa'))
const ErrorPageAuth = lazy(() => import('../views/errorPage'))
const Com400 = lazy(() => import('../views/400'))
const Com403 = lazy(() => import('../views/403'))
const Com404 = lazy(() => import('../views/404'))
const Com500 = lazy(() => import('../views/500'))


export interface RouteObject {
  caseSensitive?: boolean
  children?: RouteObject[]
  element?: React.ReactNode
  index?: boolean
  path?: string
  breadcrumbName?: string
  Navigate?: string
  key?: string
}

let routes: RouteObject[] = [
  { path: '/', element: <Login />, key: 'store' },
  { path: '/resetSuccess', element: <ResetSuccess />, key: 'resetSuccess' },
  { path: '/reset', element: <Reset />, key: 'reset' },
  { path: '/login', element: <Login />, key: 'store' },
  { path: '/mfa', element: <Mfa></Mfa>, key: 'store' },
  { path: '/mfa', element: <Mfa></Mfa>, key: 'store' },
  { path: '/authorized_error', element: <ErrorPageAuth />, key: 'store' },
  { path: '/login/store', element: <LoginStore />, key: 'store' },
  { path: '/login/brand', element: <LoginBrand />, key: 'store' },
  { path: '/resetPassword', element: <ResetPassword />, key: 'store' },
  { path: '/register', element: <Register />, key: 'store' },

  { path: '/400', element: <Com400 />, key: 'store' },
  { path: '/403', element: <Com403 />, key: 'store' },
  { path: '/500', element: <Com500 />, key: 'store' },
  { path: '/404', element: <Com404 />, key: 'store' },
  { path: '*', element: <ErrorPageAuth />, key: 'store' },
]

// The useRoutes() hook allows you to define your routes as JavaScript objects
// instead of <Routes> and <Route> elements. This is really just a style
// preference for those who prefer to not use JSX for their routes config.

export default routes
