import { ContentContainer } from '@/components/ui'
import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import './index.less'
import resetPng from '@/assets/images/reset/reset-success.png'
import { useNavigate } from 'react-router-dom'
const ResetSuccess = () => {
  const navigation = useNavigate()

  const toLogin = () => {
    window.location.href = `${process.env.REACT_APP_APP_ENV == 'prod' ? 'https://api.d2cgo.com' : 'https://api.dev.d2cgo.com'}/auth/${process.env.REACT_APP_MAIN_VERSION}/oauth/authorized?clientId=09f60950-6c2f-4283-a67f-2719ef5cf32e&loginUrl=${process.env.REACT_APP_AUTHLOGIN}&state=zero`
  }

  return (
    <div className='min-h-[100vh] min-w-[100vh] bg-[var(--gray1)]'>
      <div className='flex items-center justify-center min-h-[100vh]  w-[100%]'>
        <div className='w-[594px] h-[506px] bg-white flex items-center justify-center rounded-[10px]' style={{ boxShadow: '1px 4px 12px 1px rgba(0, 0, 0, 0.04);' }}>
          <div className='w-[387px] text-center'>
            <div>
              <img src={resetPng} alt="" width={140} className="m-auto" />
            </div>
            <div className='text-[#333333] text-[24px] font-bold'>Successful password reset!</div>
            <div className='mb-[32px] mt-[4px] text-[#6A7183] text-[14px]'>You can now use new password to log in to your account!</div>
            <Button type="primary" onClick={toLogin} className='w-full h-[40px]'>
              Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetSuccess
