export default {
  // 通用
  "public.ok": "OK",
  "public.confirm": "确认",
  "public.cancel": "取消",
  "public.delete": "删除",
  "public.back": "返回",
  "public.next": "下一步",
  "public.input": "输入",
  "public.select": "选择",
  "public.file_upload_failed": "文件上传失败",
  "public.status": "状态",
  "public.action": "操作",
  "public.edit": "编辑",
  "public.pause": "暂停",
  "public.audit": "审核",
  "public.run": "启动",
  "public.delete_item": "删除",
  "public.are_you_sure_delete": "您确认要删除?",
  "public.enable_item": "启用",
  "public.disable_item": "禁用",
  "public.yes": "是",
  "public.no": "否",
  "public.All": "全部",
  "public.search": "搜索",
  "public.reset": "重置",
  'public.details': '查看详情',
  "public.enable": "启用",
  "public.Disable": "禁用",
  "public.deleteItemTip": "您确定要删除这个选项吗?",
  "public.notice": "注意",
  'public.enableItemTip': '您确定要启用该选项吗?',
  'public.disableItemTip': '您确定要禁用该选项吗?',
  'public.actions': '操作',
  "public.Export": "导出",
  "public.save": "保存",
  "public.Edit": "编辑",
  "public.add": "添加",
  'public.pleaseSelect': '请选择',
  'public.pleaseInput': '请输入',
  'public.SKU': 'SKU',
  'public.SPU': 'SPU',

  // 登录-注册-修改密码
  "login.forget_password": "忘记密码?",
  "login.remember_me": "记住我",
  "login.login": "登录",
  "login.donot_have_an_account": "您还没有账号?",
  "login.register": "注册",
  "login.account_number": "账号",
  "login.password": "密码",
  "login.please_input_account_number": "请输入您的账号!",
  "login.please_input_password": "请输入您的密码!",
  "login.user_wrong": "用户名或密码错误!",
  "login.enter_user_name": "输入用户名",
  "login.please_input_username": "请输入您的用户名!",
  "login.enter_phone_number": "输入电话号码",
  "login.please_input_phone": "请输入您的电话号码!",
  "login.enter_valid_phone": "输入合法的号码. 如: 13101227768",
  "login.enter_password": "输入密码",
  "login.confirm_password": "确认密码",
  "login.please_confirm_password": "请确认您的密码!",
  "login.password_should_match": "两个密码不匹配!",
  "login.signup": "注销",
  "login.verify_sent_to": "您的验证码已经发送给",
  "login.select_brand": "选择品牌",
  "login.please_select_brand": "请选择品牌!",
  "login.select_store": "选择店铺",
  "login.please_select_store": "请选择店铺",
  "login.enter_verify_code": "输入验证码",
  "login.please_enter_code": "请输入验证码!",
  "login.incorrect_code": "验证码不正确!",
  "login.did_your_receive": "还没收到验证码?",
  "login.resend_code": "重新发送",
  "login.resend": "重新发送",
  "login.enter_new_password": "输入新密码",
  "login.phone_not_registed": "手机号还未注册!",
  "login.code_send_failed": "验证码发送失败，请重试!",
  "login.please_select_brand_of_store": "请选择您店铺的品牌",
  "login.provide_better_service": "让我们为您提供更好的服务",
  "login.please_select_store_of": "请选择一个店铺",
  // 头部
  "header.logout": "注销",
  // 菜单
  'menu.messagelist': '消息列表',
  'menu.messageCenter': '消息中心',
  "menu.dashboard": "看板",
  "menu.aireco": "AI推荐",
  "menu.shipment": "发货",
  "menu.my_shipment": "我的发货",
  "menu.shipping_setting": "发货设置",
  "menu.order": "订单",
  "menu.my_orders": "我的订单",
  "menu.order_setting": "订单设置",
  "menu.subscription": "订阅",
  "menu.my_subscriptions": "我的订阅",
  "menu.product": "产品",
  "menu.my_products": "我的产品",
  "menu.add_new_product": "创建产品",
  "menu.search": "搜索",
  "menu.description": "种类",
  "menu.pet_owner": "客户",
  "menu.my_pet_owner": "我的客户",
  "menu.tagging_setting": "标签设置",
  "menu.marketing_center": "营销自动化",
  "menu.intelligent_recommendation": "智能推荐",
  "menu.finance": "财务",
  "menu.payment_settings": "支付设置",
  "menu.invoice_list": "发票",
  "menu.shop": "商城",
  "menu.shop_categories": "商城分类",
  "menu.customer_service": "客户服务",
  "menu.site_map": "站内导航",
  "menu.social_media": "渠道",
  "menu.account_management": "账号管理",
  "menu.fans_management": "粉丝管理",
  "menu.automatic_replies": "自动回复",
  "menu.reply_content": "回复内容",
  "menu.assets_management": "资产管理",
  "menu.menu_management": "菜单管理",
  "menu.qr_code_management": "二维码管理",
  "menu.template_message": "模版管理",
  "menu.mini_program_qr_code": "小程序二维码",
  "menu.mini_program_banner": "小程序Banner",
  "menu.recommendation_history": "推荐历史",
  "menu.partner": "Partner",
  "menu.partner_type": "Partner Type",
  "menu.partner_list": "Partner List",
  'menu.taskList': '任务列表',
  'menu.Quick Task': 'Quick Task',

  // social netword management
  "wx.account_principal": "账号主体",
  "wx.please_input_account_principal": "请输入账号主体!",
  "wx.account_type": "账号类型",
  "wx.please_select_account_type": "请选择账户类型!",
  "wx.original_id": "Original ID",
  "wx.please_input_original_id": "请输入Original ID!",
  "wx.account_name": "账号名称",
  "wx.please_input_account_name": "请输入Account Name!",
  "wx.app_id": "应用Id",
  "wx.please_input_appid": "请输入应用ID!",
  "wx.merchat_id": "商户ID",
  "wx.app_secret": "应用密钥",
  "wx.please_input_app_secret": "请输入应用密钥",
  "wx.merchant_key": "商户Key",
  "wx.management_mode": "管理模式",
  "wx.please_select_mode": "请选择管理模式!",
  "wx.push_server": "推送服务器",
  "wx.official_account_type": "公众号类型",
  "wx.please_select_official_account_type": "请选择公众号类型!",
  "wx.message_url": "消息加密Url",
  "wx.please_input_message_url": "请输入消息加密Url!",
  "wx.token": "Token",
  "wx.please_input_token": "请输入oken!",
  "wx.certificate_path": "证书地址",
  "wx.qr_code_path": "二维码地址",
  "wx.description": "描述",
  "wx.click_to_select": "点击选择",
  "wx.asso_account": "分配账户",
  "wx.add_account": "增加账户",
  "wx.edit_account": "修改账户",
  "wx.view_qr_code": "查看二维码",
  "wx.account_enable_tip1": "是否确认要启用官方帐户吗？如果是，微信管理的其他模块（粉丝管理、资产管理、模块管理…）将仅与活动账户关联.",
  "wx.account_enable_tip2": "是否确认启用这个账户?",
  "wx.account_disable_tip1": "是否确认要禁用官方帐户？如果是，微信管理的其他模块（粉丝管理、资产管理、模块管理…）将仅与活动账户关联.",
  "wx.account_disable_tip2": "是否确认禁用这个账户?",

  //Al Reco Models
  "menu.aireco_models": "AI推荐",


  //marketing
  "marketing.recom_basic_setting": "推荐基础设置",
  "marketing.recom_basic_setting_t1": "商品推荐管理",
  "marketing.recom_basic_setting_s1": "手动设置商品数量或禁用某些商品",
  "marketing.recom_strategy_manage": "推荐策略管理",
  "marketing.recom_strategy_manage_t1": "最佳推荐",
  "marketing.recom_strategy_manage_s1": "根据整体维度对商品进行排序。通常用于“热销商品”栏展示。",
  "marketing.recom_strategy_manage_t2": "商品关联推荐",
  "marketing.recom_strategy_manage_s2": "通过与当前商品的关系进行推荐，以促进同时购买。通常用于商品详情页“相关商品”栏展示。",
  "marketing.recom_strategy_manage_t3": "用户行为推荐",
  "marketing.recom_strategy_manage_s3": "根据用户浏览行为和偏好进行个性化推荐。通常用于展示在“您可能还喜欢”处。",
  "marketing.recom_strategy_manage_t4": "商品搜索",
  "marketing.recom_strategy_manage_s4": "根据用户输入的订阅类型和宠物等信息进行个性化推荐。通常展示在“搜索结果”页面。",
  "marketing.recom_spot_manage": "推荐展示管理",
  "marketing.recom_spot_manage_t1": "购物车",
  "marketing.recom_spot_manage_s1": "根据商品关联度进行推荐",
  "marketing.recom_spot_manage_t2": "商品详情",
  "marketing.recom_spot_manage_s2": "根据用户行为偏好进行推荐",
  "marketing.recom_spot_manage_t3": "商品列表",
  "marketing.recom_spot_manage_s3": "最佳推荐",
  "marketing.recom_spot_manage_t4": "我的资料",
  "marketing.recom_spot_manage_s4": "根据用户行为偏好进行推荐",
  "marketing.recom_spot_manage_t5": "会员中心",
  "marketing.recom_spot_manage_s5": "根据用户行为偏好进行推荐",
  "marketing.recom_spot_manage_t6": "订单确认页",
  "marketing.recom_spot_manage_s6": "最佳推荐",
  "marketing.recom_spot_manage_t7": "分类",
  "marketing.recom_spot_manage_s7": "根据商品关联度进行推荐",
  "marketing.recom_spot_manage_t8": "分类推荐栏",
  "marketing.recom_spot_manage_s8": "根据用户行为偏好进行推荐",
  "marketing.marketing_center": "市场营销中心",
  "marketing.boost_sale_promo": "通过促销促进销售",
  "marketing.boost_sale_promo_t1": "凭证",
  "marketing.boost_sale_promo_s1": "通过使用优惠券在结账时向买家提供折扣价格，增加订单",
  "marketing.boost_sale_promo_t2": "打折促销活动",
  "marketing.boost_sale_promo_s2": "为你的产品设置折扣来促进销售",
  "marketing.boost_sale_promo_t3": "活动",
  "marketing.boost_sale_promo_s3": "通过在你的商店里创建活动来促进产品的销售",
  "marketing.engage_petowner": "与你的宠物主人交流",
  "marketing.engage_petowner_t1": "在线直播",
  "marketing.engage_petowner_s1": "与你的听众联系，轻松回答购物者的问题",

  // voucher
  "voucher.orders": "订单",
  "voucher.orders.Search": "搜索",
  "voucher.orders.BasicInformation": "基本信息",
  "voucher.orders.Quantity": "数量",
  "voucher.orders.TotalNumberOfVouchers": "凭证总数",
  "voucher.orders.All": "全部",
  "voucher.orders.SingleOrder": "单独的订单",
  "voucher.orders.NormalSubscription": "正常的订阅",
  "voucher.orders.ShopVoucher": "店铺优惠券",
  "voucher.orders.ProductVoucher": "商品优惠券",
  "voucher.orders.VoucherName": "优惠券名称",
  "voucher.orders.OrderType": "订单类型",
  "voucher.orders.MinimumBasketPrice": "最低篮子价格",
  "voucher.orders.DiscountAmount": "折扣金额",
  "voucher.orders.Valid Period": "有效期",
  "voucher.orders.Voucher Type": "优惠券类型",
  "voucher.orders.Applicable Products": "适用的产品",
  "voucher.orders.Claimed": "声称",
  "voucher.orders.Usage": "使用",
  "voucher.orders.Products": "产品",
  "voucher.orders.All Products": "全部产品",
  "voucher.Unlimited": "无限的",
  "voucher.orders.Collapse Voucher Information": "展开凭证信息",
  "voucher.orders.More Voucher Information": "更多优惠券信息",
  "voucher.list": "优惠券列表",
  "voucher.list.Data from": "数据从",
  "voucher.list.to": "至",
  "voucher.list.UTC+8": "UTC+8",
  "voucher.list.Status": "状态",
  "voucher.list.Valid Period": "有限期",
  "voucher.list.Ongoing": "正在进行的",
  "voucher.list.Upcoming": "即将到来的",
  "voucher.list.Expired": "已过期",
  "voucher.list.Are you sure you want to": "您确定想要",
  "voucher.list.the item?": "该项目吗？",
  "voucher.list.listDescription": "在DTC中心为您的店铺和产品创建和管理自己的代金券！",
  "voucher.list.Create": "创建",
  "voucher.SKU": "SKU",
  "voucher.SPU": "SPU",
  "voucher.Products": "商品",
  "voucher.Brand": "品牌",
  "voucher.Price(s)": "价格",
  "voucher.Stock": "存库",
  "voucher.Actions": "操作",
  "voucher.Product Category:": "产品类别:",
  "voucher.All Category": "全部类别",
  "voucher.spu:": "spu:",
  "voucher.Select a option and change input text above": "选择一个选项并更改上面的输入文本",
  "voucher.Applicable Products": "适用的产品",
  "voucher.Add Products": "添加产品",
  "voucher.Product(s) Selected": "已勾选产品",
  "voucher.Picture upload failed!": "优惠券图片上传失败！",
  "voucher.Image must smaller than 1M!": "图片大小1M以内！",
  "voucher.Basic Information": "基本信息",
  "voucher.Shop Voucher": "店铺优惠券",
  "voucher.Product Voucher": "商品优惠券",
  "voucher.Voucher Type": "优惠券类型",
  "voucher.Voucher Name": "优惠券名称",
  "voucher.Voucher Description": "优惠券描述",
  "voucher.Voucher Code": "优惠券代码",
  "voucher.Valid Period": "有效期",
  "voucher.Automatic Offer": "自动提供",
  "voucher.Voucher Image": "优惠券图片",
  "voucher.The recommended size for images is 100px * 100px.": "图片的推荐尺寸为100px * 100px。",
  "voucher.Image size should not exceed 1M.": "图像大小不应超过1M。",
  "voucher.Note: One consumer can only use once.": "注意:一个消费者只能使用一次。",
  "voucher.Fix Amount": "修正量",
  "voucher.By Percentage": "通过百分比",
  "voucher.Rule Settings": "规则设置",
  "voucher.Please enter a value between 1 and 99": "请输入1到99之间的值",
  "voucher.Discount Type | Amount": "折扣类型|金额",
  "voucher.Recurrence": "递归式",
  "voucher.Minimum Basket Price": "最低满减价格",
  "voucher.Usage Quantity": "使用数量",
  "voucher.The price cannot be less than the voucher discount amount.": "价格不能低于代金券折扣金额。",
  "voucher.Quantity": "数量",
  "voucher.Total usable voucher for all pet owners": "所有宠物主人的可用凭证",
  "voucher.orders.usage.tip": '已使用凭证数量(不含已取消及订单)',
  "voucher.orders.claimed.tip": '已认领的凭证数量',
  'voucher.keyMetrics': '关键指标',
  'voucher.GSV': 'GSV',
  'voucher.GSVTip': '使用凭证确认的所有订单的总额。',
  'voucher.order': '订单',
  'voucher.orderTip': '使用凭证确认的订单总数。',
  'voucher.usageRate': '使用率',
  'voucher.usageRateTip': '确认订单中使用凭证的总数除以凭证索赔的总数。',
  'voucher.petOwners': '宠物的主人',
  'voucher.petOwnersTip': '在确认订单中使用代金券的唯一宠物主人总数。',
  'voucher.selectProduct': '选择商品',

  // templateMessage
  "templateMessage.View Industries": "查看产业",
  "templateMessage.Synchronous": "同步",
  "templateMessage.Graphical Representation": "图形化表示",
  "templateMessage.Add": "添加",
  "templateMessage.Template ID": "模板 ID",
  "templateMessage.Please input Template ID": "请输入模板 ID",
  "templateMessage.Scenario": "场景",
  "templateMessage.Title": "标题",
  "templateMessage.Template Title": "模板标题",
  "templateMessage.Status": "状态",
  "templateMessage.Add New Template": "添加新模板",
  "templateMessage.Select Template": "选择模板",
  "templateMessage.Shipped": "已发货",
  "templateMessage.Cancel Reminder": "取消提醒",
  "templateMessage.Select Scenario": "选择场景",
  "templateMessage.List Representation": "列表表示",
  "templateMessage.Modify": "修改",
  "templateMessage.Template ID:": "模板 ID:",
  "templateMessage.Template Name:": "模板名称:",
  "templateMessage.Please input Template Name": "请输入模板名称",
  "templateMessage.Select Products": "选择产品",
  "templateMessage.View Industry": "查看产业",
  "templateMessage.Industry": "产业",
  "templateMessage.Primary Industry": "一级产业",
  "templateMessage.Please input Primary Industry": "请选择一级产业",
  "templateMessage.Secondary Industry": "二级产业",
  "templateMessage.Please input Secondary Industry": "请选择二级产业",
  "templateMessage.H5 Jump Path": "H5地址",
  "templateMessage.Please input H5 jump path": "请输入H5地址",
  "templateMessage.Mini Program AppId": "小程序AppId",
  "templateMessage.Please input Mini Program appid": "请选择小程序AppId",
  "templateMessage.Jump Path": "跳转地址",
  "templateMessage.Please input Mini Program Jump Path": "请输入小程序跳转地址",
  "templateMessage.Description": "描述",
  "templateMessage.Please input Description": "请输入描述",

  // tag
  "tag.Tagging Name:": "标签名称:",
  "tag.Pet Owner(s)": "宠物的主人",
  "tag.Tagging Status": "标签状态",
  "tag.Options": "选项",
  "tag.Add New Tag": "添加新标签",
  "tag.Missing Display Name": "失踪的显示名称",
  "tag.Enter a tagging name": "输入一个标签名称",
  "tag.Profile Photo": "头像",
  "tag.WeChat Name": "微信名",
  "tag.Phone Number": "手机号",
  "tag.Pet Owner List": "宠物主人列表",
  "tag.Add New Pet Owner": "添加新的宠物主人",
  "tag.Select Pet Owner": "选择宠物的主人",
  "tag.Price": "价格",
  "tag.Stock": "库存",
  "tag.Actions": "操作",
  'tag.tagNameTip': '输入标签名称',

  // subscription
  "subscription.Ongoing": "进行中",
  "subscription.Paused": "暂停",
  "subscription.Completed": "完成",
  "subscription.Inactive": "不活跃的",
  "subscription": "订阅",
  "subscription.Restart Subscription": "重启订阅",
  "subscription.Pause Subscription": "暂停订阅",
  "subscription.Subscription Creation Date": "订阅创建日期",
  "subscription.Subscription ID": "订阅 ID",
  "subscription.Subscription Cycle": "订阅周期",
  "subscription.Order ID": "订单 ID",
  "subscription.Product Name": "商品名称",
  "subscription.Phone Number": "手机号",
  "subscription.Wechat Name": "微信名",
  "subscription.Actions": "Actions",
  "subscription.Product(s)": "商品",
  "subscription.Subscription Status": "订阅状态",
  "subscription.Subscription Type": "订阅类型",
  "subscription.Variation": "变异",
  "subscription.Restart": "重启",
  "subscription.Pause": "暂停",
  "subscription.Subscription Time": "订阅时间",
  "subscription.Pet Detail": "宠物详情",
  "subscription.Age": "年龄",
  "subscription.Breed": "品种",
  "subscription.Product": "商品",
  "subscription.Unit price": "单价",
  "subscription.Quantity": "数量",
  "subscription.Freshness": "新鲜度",
  "subscription.Subtotal": "小计",
  "subscription.Subscription Product": "订阅产品",
  "subscription.PauBenefit Namese": "受益的名字",
  "subscription.Benefit Type": "收益型",
  "subscription.Subscription Benefits": "订阅的好处",
  "subscription.View Order Detail": "查看订单详情",
  "subscription.SEQ": "SEQ",
  "subscription.Shipment date": "发货日期",
  "subscription.Order status": "订单状态",
  "subscription.To start": "开始",
  'subscription.info': '订阅信息',

  'comment.remarks': '评论',
  'comment.placeholder': '输入评论',
  'comment.inputTip': '请输入你的评论',
  'operation.log': '操作日志',

  // Shipping
  "Shipping.Shipping Setting": "运费设置",
  "Shipping.Express 100": "快递100",
  "Shipping.Edit Express 100 setting": "编辑快递100设置",
  "Shipping.Key:": "Key:",
  "Shipping.Consumer:": "消费者:",
  "Shipping.Please input consumer": "请输入消费者",
  "Shipping.Pull URL:": "拉取地址:",
  "Shipping.please input user name": "请输入url",
  "Shipping.Query URL:": "查询地址:",
  "Shipping.please input url": "请输入url",
  "Shipping.SCallback URL:": "回调地址:",
  "Shipping.Please input callbackUrl": "请输入url",
  "Shipping.Please input key": "请输入key",
  "Shipping.SEQ": "SEQ",

  'resetPassword.phoneNumber': '手机号',
  'resetPassword.resetPassword': '重置密码',

  // reply
  "reply.Confirm Delete?": "确定删除？",
  "reply.this item?": "该项目？",
  "reply.Are you sure you want to": "您确定想要",
  "reply.Reply Type": "回复类型",
  "reply.Content description": "内容描述",
  "reply.Add New Reply Content": "添加新回复内容",
  "reply.Edit Reply Content": "编辑回复内容",
  "reply.Please select reply type!": "请选择回复类型！",
  "reply.Content Description": "内容描述",
  "reply.Please input description!": "请输入描述！",
  "reply.Asset": "资源",
  "reply.Please select Asset!": "请选择资源！",
  "reply.Message content": "消息内容",
  "reply.Assets Title": "资源标题",
  "reply.Wechat Assets ID": "微信资源 ID",
  "reply.Create Time": "创建时间",
  "reply.Picture": "图片",
  "reply.Voice": "音频",
  "reply.Video": "视频",
  "reply.Graphic main cover": "图形主要覆盖",
  "reply.Title": "标题",
  "reply.Select Assets": "选择资源",
  'reply.textMessage': '文本消息',
  'reply.pictureMessage': '图片消息',
  'reply.voiceMessage': '音频消息',
  'reply.videoMessage': '视频消息',
  'reply.graphicMessage': '图形消息',

  // social netword management
  "wx.add_new_auto_replay": "新增自动回复",
  "wx.edit_auto_reply": "编辑自动回复",
  "wx.match_type": "匹配类型",
  "wx.keywords": "关键字",
  "wx.please_input_keywords": "请输入关键字",
  "wx.reply_desc": "回复内容描述",
  "wx.please_select_reply_content": "请选择回复内容！",
  "wx.content_desc": "内容描述",
  "wx.reply_type": "回复类型",
  "wx.select_reply_content": "选择回复内容",
  "wx.pls_upload_thumb": "请上传缩略图！",
  "wx.pls_upload_asso_assets": "请上传相关资源！",
  "wx.save_and_sync": "保存并同步",
  "wx.add_graphic_message": "新增图文消息",
  "wx.title": "标题",
  "wx.pls_input_title": "请输入标题！",
  "wx.author": "作者",
  "wx.link": "链接",
  "wx.pls_input_valid_url": "请输入有效链接地址！",
  "wx.link_for_article": "“阅读原文”的链接地址",
  "wx.content": "内容",
  "wx.pls_input_content": "请输入内容！",
  "wx.cover_preview": "封面预览",
  "wx.suggest_size": "建议大小",
  "wx.large_pic_size": "大图: 900*500（单图文消息或多图文消息的首条消息）",
  "wx.small_pic_size": "小图: 200*200（多图文消息的非首条消息）",
  "wx.abstract": "摘要",
  "wx.abstract_tip": "摘要（可选输入，若未输入，则默认摘取前45个字符）",
  "wx.add_picture_message": "新增图片消息",
  "wx.add_video_message": "新增视频消息",
  "wx.add_voice_message": "新增音频消息",
  "wx.single_mess_del_tip": "单图文消息无法被删除！",
  "wx.are_you_sure_to_delete_message": "您确认要删除该消息吗？",
  "wx.new_graphic": "新图文",
  "wx.select_exist_graphic": "选择已有图文",
  "wx.picture_message": "图片消息",
  "wx.voice_message": "音频消息",
  "wx.video_message": "视频消息",
  "wx.add_new_message": "新增一个消息",
  "wx.picture": "图片",
  "wx.wechat_assets_link": "微信资源链接",
  "wx.create_time": "创建时间",
  "wx.voice": "音频",
  "wx.graphic_main_cover": "图文消息封面",
  "wx.assets_id": "微信资源ID",
  "wx.pictures": "图片",
  "wx.video": "视频",
  "wx.videos": "视频",
  "wx.voices": "音频",
  "wx.graphic_messages": "图文消息",
  "wx.graphic_message": "图文消息",
  "wx.picture_assets": "图片资源",
  "wx.voice_assets": "音频资源",
  "wx.video_assets": "视频资源",
  "wx.add_video": "新增视频",
  "wx.select_file": "选择文件",
  "wx.file": "文件",
  "wx.pls_upload_file": "请上传文件",
  "wx.pls_input_desc": "请输入描述！",
  "wx.confirm_sync": "确认同步",
  "wx.are_you_sure_to_sync": "您确认要同步吗？",
  "wx.main_cover": "封面",
  "wx.view_details": "查看详情",
  "wx.publish": "发布",
  "wx.sync_wechat_assets": "同步微信资源",
  "wx.publish_item": "发布项目",
  "wx.are_you_sure_to_publish": "您确认要发布该项目吗？",
  "wx.view_image": "预览图片",
  "wx.view_video": "预览视频",
  "wx.wechat_account": "微信账号",
  "wx.response_type": "回复类型",
  "wx.response_desc": "回复描述",
  "wx.fans_detail": "粉丝详情",
  "wx.wechat_name": "微信昵称",
  "wx.unionid": "UNIONID",
  "wx.openid": "OPENID",
  "wx.follow_time": "关注时间",
  "wx.comment": "评论",
  "wx.channel_qr": "二维码",
  "wx.is_member": "是否会员",
  "wx.sync_fans_all": "同步所有粉丝",
  "wx.sync_fans_all_con": "您确认同步所有粉丝吗？粉丝数量可能较大，请等待",
  "wx.sync_fans_tip": "粉丝正在同步，完成后将自动更新该页内容",
  "wx.sync_fans_openid": "同步已选粉丝",
  "wx.avatar": "头像",
  "wx.sync_partial": "部分同步",
  "wx.sync_partial_tip": "请至少选择一项",
  "wx.sync_all": "同步所有",
  "wx.sync_livestream": "同步直播信息",
  "wx.all": "全部",
  "wx.ongoing": "正在进行",
  "wx.upcoming": "即将开始",
  "wx.expired": "已结束",
  "wx.mini_program": "小程序",
  "wx.livestream_id": "直播ID",
  "wx.livestream_name": "直播名称",
  "wx.anchor_name": "主播",
  "wx.period": "起止时间",
  "wx.sync_live_tip": "请选择您要同步的项目",
  "wx.share": "分享",
  "wx.pls_add_menu": "请新增菜单",
  "wx.pls_add_menu_name": "请输入菜单名称",
  "wx.add_new_menu": "新菜单",
  "wx.edit_menu": "编辑菜单",
  "wx.menu_name": "菜单名称",
  "wx.save_and_publish": "保存并发布",
  "wx.response_should_choose_asset": "回复类型菜单请先选择资源！",
  "wx.redirect_should_contain_url": "跳转网页的菜单请输入有效链接地址！",
  "wx.miniprogram_should_complete_setting": "请完成跳转小程序的菜单设置！",
  "wx.menu_valid_fail": "菜单设置信息校验失败",
  "wx.gen_menu_data": "生成菜单配置",
  "wx.click_to_jump_miniprogram": "用户点击菜单跳转的小程序",
  "wx.url": "地址",
  "wx.page_path": "页面路径",
  "wx.click_to_jump_link": "用户点击菜单跳转的网址",
  "wx.page_url": "网址",
  "wx.select_from_assets": "从资源库选择",
  "wx.menu_item_delete_tip": "您确认要删除菜单吗？删除后的菜单设置无法恢复",
  "wx.move_left": "左移",
  "wx.move_right": "右移",
  "wx.move_up": "上移",
  "wx.move_down": "下移",
  "wx.menu_name_length": "建议不超过4个汉字或8个英文字符",
  "wx.send_response_message": "发送回复消息",
  "wx.web_redirection": "跳转网页",
  "wx.joint_with_miniprogram": "关联小程序",
  "wx.upload": "上传",
  "wx.update_time": "更新时间",
  "wx.add_new_banner": "新增小程序Banner",
  "wx.edit_banner": "编辑小程序Banner",
  "wx.banner_name": "Banner名称",
  "wx.page": "页面",
  "wx.home_page": "首页",
  "wx.shop_page": "商城页",
  "wx.pic_location": "图片地址",
  "wx.click_type": "点击事件",
  "wx.no_operation": "无操作",
  "wx.open_web_page": "打开网页",
  "wx.open_mp_page": "打开小程序页面",
  "wx.open_other_mp_page": "打开其它小程序页面",
  "wx.mini_program_id": "小程序ID",
  "wx.sort": "排序",
  "wx.qr_code_detail": "二维码详情",
  "wx.add_new_qr_code": "新增二维码",
  "wx.channel_type": "频道",
  "wx.qr_code_type": "二维码类型",
  "wx.qr_code_color": "二维码颜色",
  "wx.scenario_type": "场景类型",
  "wx.qr_code_key_value": "二维码键值",
  "wx.mini_program_path": "小程序路径",
  "wx.qr_code_size": "二维码大小",
  "wx.qr_code_size_recom": "最小280，最大1280",
  "wx.trans_background": "背景透明",
  "wx.channel": "频道",
  "wx.scenario": "场景",
  "wx.qr_code_value": "二维码内容",

  'aiRecoModel.id': '模型ID',
  'aiRecoModel.aiRecoModel': 'AI推荐模型',
  "aiRecoModel.history.userName": "用户名",
  "aiRecoModel.history.phone": "手机号",
  "aiRecoModel.history.model": "模型",
  "aiRecoModel.history.modelNameOrId": "模型名称/id",
  "aiRecoModel.history.date": "日期",
  "aiRecoModel.history.userSearchingInput": "用户输入",
  "aiRecoModel.history.recProductNames": "推荐产品",
  "aiRecoModel.history.skus": "SKU(s)",
  "aiRecoModel.history.recomTime": "推荐时间",

  // qrCode
  "qrCode.Temporary integer parameter": "临时整数参数",
  "qrCode.Temporary string parameter": "临时字符串参数",
  "qrCode.Permanent integer parameter": "永久的整数参数",
  "qrCode.Permanent string parameter": "永久的字符串参数",
  "qrCode.QR Code Name": "二维码名称",
  "qrCode.Expired Time": "失效时间",
  "qrCode.QR Code Type": "二维码类型",
  "qrCode.Wechat Account": "微信号",
  "qrCode.Official Account": "公众号",
  "qrCode.Add QR Code": "添加二维码",
  "qrCode.Please input QR Code Name!": "请输入二维码名称!",
  "qrCode.Please select QR Code Type!": "请选择二维码类型!",
  "qrCode.Scenario ID": "场景ID",
  "qrCode.Please input Scenario ID!": "请输入场景ID!",
  "qrCode.Comment": "评论",
  "qrCode.Scenario STR": "场景STR",
  "qrCode.Please input Scenario STR, and do not contain _": "请输入场景STR，不包含_",
  "qrCode.Response Content": "响应内容",
  "qrCode.Please select Reply Content!": "请选择“回复内容”!",
  "qrCode.Mini Program": "小程序",
  "qrCode.Banner Name": "横幅的名字",
  "qrCode.Pic Location": "图片的位置",
  "qrCode.Click Type": "点击类型",
  "qrCode.Default": "默认的",
  "qrCode.Path": "路径",
  "qrCode.Sort": "排序",

  // product
  "product.category_cannot_active": "此类别无法激活，因为它不包含任何产品",
  "product.create_by": "创建于",
  "product.products": "产品",
  "product.product_list": "产品列表",
  "product.product_will_add_to_category":
    "如果您的产品满足过滤规则的标准，它们将自动添加到您的商店类别",
  "product.set_filter_rule": "设置过滤规则",
  "product.edit_filter_rule": "编辑过滤规则",
  "product.product_name": "产品名称",
  "product.price": "价格",
  "product.stock": "库存",
  "product.products_found": "找到{num}个产品",
  "product.product_s": "产品",
  "product.brand": "品牌",
  "product.price(s)": "价格",
  "product.category_name": "类别名称",
  "product.product_category": "产品类别",
  "product.specification": "规范",
  "product.market_price": "市场价格",
  "product.filter_results": "筛选结果",
  "product.add_products": "添加产品",
  "product.details": "详情",
  "product.my_shop_category": "我的商店类别",
  "product.add_category": "添加类别",
  "product.category_display_name": "类别显示名称",
  "product.display_on_off": "显示开/关",
  "product.manual_selection": "手动选择",
  "product.manual_selection_tip": "手动选择要包含在商店类别中的产品",
  "product.rule_based_filter": "基于规则的过滤",
  "product.rule_based_tip": "产品将根据您设置的过滤器自动选择",
  "product.miss_category_name": "失踪的类别名称",
  "product.enter_category_name": "输入类别名称",
  "product.category_type": "类别类型",
  "product.adjust_seq": "调整顺序",
  "product.miss_display_name": "失踪的显示名称",
  "product.enter_display_name": "输入类别显示名称",
  "product.search_products": "搜索产品",
  "product.products_selected": "选择了{num}个产品",
  "product.category": "类别",
  "product.select_opt_and_change": "选择一个选项并更改上面的输入文本",
  "product.select_products": "选择产品",
  "product.Top Search Name": "搜索的名字",
  "product.Priority": "优先级",
  "product.Top Search is visible on shop": "顶部搜索在店铺可见",
  "product.Add New Top Search": "添加新的顶部搜索",
  "product.To Search Name": "搜索的名字",
  "product.Add a New Product": "添加一个新产品",
  "product.No category has been chosen": "没有选定类别",
  "product.Product Type": "产品类型",
  "product.Regular": "规则",
  "product.Bundle": "包",
  "product.Select a option and change input text above": "选择一个选项并更改上面的输入文本",
  "product.Choose Product Type": "选择产品类型",
  "product.Choose Category": "选择类别",
  "product.Delete Product": "删除产品",
  "product.deleteProductTip": "您确定要删除产品吗?警告:您无法撤消此操作!",
  "product.More": "更多",
  "product.Products SKUs": "产品 SKUs",
  "product.Hide": "隐藏",
  "product.Delist": "下架",
  "product.Publish": "上架",
  "product.Are you sure want to delete the following product ? Warning: You cannot undo this action!": "您确定要删除以下产品吗?警告:您无法撤消此操作!",
  "product.Live": ' 现货',
  "product.Sold out": '售罄',
  "product.Delisted": '下架',
  'product.Variations': '变化',
  'product.Preview': '预览',
  'product.chooseCategoryTip': '请为您的产品选择合适的类别',
  'product.update': '更新',
  'product.baseInfo': '基础信息',
  'product.salesInfo': '销售信息',
  'product.Shipping': '运费',
  'product.specificationTip': '填写更多的属性来提高你的产品的曝光率。',
  'product.productImage': '商品图片',
  'product.productImageTip1': '每张图片需要较好分辨率，以便放大时不会发生像素模糊',
  'product.productImageTip2': '最多添加9张图片',
  'product.productVideo': '图片视频',
  'product.productVideoTip1': '1. 大小：30MB以内, 分辨率不超过1280x1280px',
  'product.productVideoTip2': '2. 播放时长：10s-60s',
  'product.productVideoTip3': '3. 格式：MP4',
  'product.productVideoTip4': '4. 其它说明：您可以继续添加，上传成功后会自动显示',
  'product.description': '产品描述',
  'product.spuTip': 'SPU：<p>SPU应唯一并遵循编码规则</p>',
  'product.productNameTip': '产品名称：<p>简要描述产品</p>',
  'product.descriptionTip': '`产品描述：\n' +
    '        包含<br/>\n' +
    '        1. 品牌<br/>\n' +
    '        2. 重量<br/>\n' +
    '        3. 营养组成<br/>\n' +
    '        4. 喂养指南<br/>\n' +
    '        5. 推荐理由<br/>\n' +
    '        6. 质量保证<br/>\n' +
    '        `',
  'product.salesStatusTip': '销售状态：\n<p>可销售状态是指在商城显示并可购买</p>\n<p>非销售状态是指不在商城展示且不能被购买</p>',
  'product.salesStatus': '销售状态',
  'product.Saleable': '可销售',
  'product.Not saleable': '不可销售',
  'product.productTypeRequired': '须选择商品类型',
  'product.productNameRequired': '须填入商品名称',
  'product.The currently selected': '当前选择',
  'product.Tips': '提示',
  'product.Save and Publish': '保存并上架',
  'product.Save and Delist': '保存并下架',
  'product.zone': '领域',
  'product.breeds': '品种',
  'product.Life Stage': '生命周期',
  'product.Functions': '功效',
  'product.Size': '大小',
  'product.Technology': '工艺',
  'product.List Price': '订价',
  'product.Subscription Price': '订阅价',
  'product.Feeding Days': '饲养天数',
  'product.Support 100': 'Support 100',
  'product.image': '图片',
  'product.variationImageTip': '维度图片:\n  <p>商品维度描述图片应保持清晰并和商品维度名称一致。</p>\n  <p>设置每个维度的图片时，图片格式应保持一致。</p>',
  'product.Live/Dellist': '上架/下架',
  'product.Live/DellistTip': '上架/下架:\n' +
    ' <p>上架: SKU已上架</p>\n' +
    '  <p>下架: SKU已下架</p>',
  'product.support100Tip': 'Support 100:\n<p>商品生产日期在100天以内请设置为Y，超过100天请设置为N。</p>',
  'product.feedingDays': '饲养天数:\n  <p>请根据产品规格设置合理的饲养天数。</p>',
  'product.stockTip': '库存:\n    <p>库存是指实际可发货的库存数量。</p>\n    <p>如果没有库存，请设置为0，以减少库存预警率和延迟发货率。</p>',
  'product.subscriptionPriceTip': '订阅价:\n  <p>订阅价应包含税款并低于市场价格。</p>\n  <p>非订阅的商品不能输入此项。</p>',
  'product.marketingPriceTip': '市场价:\n  <p>市场价应包含税款且保持稳定。</p>',
  'product.listPriceTip': '定价:\n  <p>定价应包含税款且保持稳定。</p>',
  'product.SubscriptionTip': '订阅:<p>支持订阅请设置为Y</p>    \n    <p>不支持订阅请设置为N</p>',
  'product.EAN': 'EAN',
  'product.EANTip': 'EAN:\n    <p>EAN应与SKU相关并遵循编码规则</p>',
  'product.Sub-SKU': 'Sub-SKU',
  'product.Sub-SKUTip': 'Sub-SKU:<p>Sub-SKU是指与此商品绑定销售的子商品SKU</p>',
  'product.skuName': 'SKU名称',
  'product.skuNameTip': 'SKU名称:\n  <p>SKU名称应和商品维度相关</p>',
  'product.skuTip': 'SKU:<p>SKU应唯一并且遵循编码规则</p>',

  "public.Detail": "详情",
  "public.upload": "上传",
  "public.file_upload_success": "文件上传成功",
  "public.are_you_sure_enable": "您确定要启用吗？",
  "public.are_you_sure_disable": "您确定要禁用吗？",
  "public.confirm_delete": "删除确认",
  "public.operate_success": "操作成功",
  "public.operate_fail": "操作失败",
  "public.Operation Successful": "操作成功",
  "public.Options": "选项",
  "public.option": "选项",
  "public.Please Input": "请输入",
  "public.Please Select": "请选择",
  "public.field_required": "这是必填项",
  "public.Are you sure you want to restart this subscription?": "您确定要重启订阅吗？",
  "public.Are you sure you want to pause this subscription?": "您确定要暂停订阅吗？",
  "public.no.": "No.",
  "public.download": "下载",
  "public.pleaseEnter": "请输入",
  "public.succeed": "成功！",
  "public.file_upload_only_jpg": "只能上传JPG/PNG格式的图片！",
  "public.file_upload_smaller_than_2M": "图片须在2MB以内！",
  "public.confirm_tip": "您确定要继续操作吗？",
  "login.expired_tip": "登录失效，请重新登录！",
  "header.dtc_center": "DTC Experience",
  "header.efficient_store_tool": "Efficient store management tools",
  "header.back_to_login_page": "返回登录页面",
  "wx.exact_matching": "精确匹配",
  "wx.fuzzy_matching": "模糊匹配",
  "dashboard.shipment": "发货",
  "dashboard.shipment_desc": "提供发货工具以便为用户安排发货。",
  "dashboard.order": "订单",
  "dashboard.order_desc": "管理订单状态、查询订单信息和发表或回复评论。",
  "dashboard.subscription": "订阅",
  "dashboard.subscription_desc": "查询订阅信息、修改发货时间、修改收货地址以及发表或回复评论。",
  "dashboard.product": "商品",
  "dashboard.product_desc": "创建常规或组合商品，管理品类、商品图片、商品属性和规格。",
  "dashboard.marketing": "营销中心",
  "dashboard.marketing_desc": "管理折扣和优惠券设置，以及其它可以帮助增强购物体验和提升潜在客户的能力。",
  "dashboard.channel": "社交平台",
  "dashboard.channel_desc": "管理平台账号、粉丝信息、自动回复内容、公众号菜单设置、图文消息等设置。",
  "dashboard.solution": "D2C解决方案",
  "dashboard.solution_desc": "通过在战略、咨询、数字化、技术和运营方面提供无与伦比的服务，解决客户的痛点。",
  "dashboard.apidoc": "API文档",
  "dashboard.apidoc_desc": "包含所有关于API有用的信息，包括功能详细描述、实体类信息、类型、参数等",
  "dashboard.main": "核心能力",
  "dashboard.more": "探索更多",
  "customer.customer_service": "客户服务",
  "customer.dtc_center_support": "DTC Center支持启用和禁用商城端的客服组件。",
  "customer.mini_program": "小程序",
  "product.product": "商品",
  "product.Are you sure want to delete the product(s) ? Warning: You cannot undo this action!": "您确定要删除这些商品吗？删除后无法恢复！",
  "product.Variation": "维度",
  "product.variationTip": "可以设置两个维度，第一个更重要",
  "product.name": "名称",
  "product.addOption": "添加选项",
  "product.addVariation": "新增维度",
  "product.weight": "重量",
  "product.weightTip": "商品重量：\\n<p>请输入商品运输重量，包括净重加包装重量。</p>",
  "product.parcelSize": "包装尺寸",
  "product.parcelSizeTip": "包装尺寸：\\n<p>请输入含包装的尺寸，而非商品本身的尺寸。方便运送时准确无误。</p>",
  "product.attributeTip": "商品属性：\\n    <p>商品属性应和商品名称或描述相关。</p>",
  "voucher.Voucher Value": "优惠信息",
  "voucher.list.usageLimit": "使用限制",
  "voucher.usageTip": "已使用的优惠券数量（不包含已取消的订单）",
  "voucher.Orders": "订单",
  "voucher.end": "终止",
  "subscription.auto_ship": "Autoship",
  "subscription.quarter": "每季度",
  "subscription.half_year": "每半年",
  "subscription.year": "每年",
  "subscription.normal": "常规",
  "subscription.100": "100",
  "subscription.status.created": "订阅已创建",
  "subscription.status.refill": "订单已创建",
  "subscription.status.pause": "订阅已暂停",
  "subscription.status.resume": "订阅已重启",
  "subscription.status.completed": "订阅已完成",
  "subscription.log.modify_address": "修改收货地址",
  "subscription.log.modify_ship_date": "修改发货日期",
  "subscription.log.create_comment": "发表评论",
  "subscription.log.modify_comment": "修改评论",
  "subscription.log.delete_comment": "删除评论",
  "aiRecoModel.Detail": "模型详情",
  "aiRecoModel.edit": "编辑模型",
  "aiRecoModel.list.subTitle": "选择模型后，可以开启或关闭，也可以设置模型内具体的参数。",
  "aiRecoModel.name": "模型名称",
  "aiRecoModel.latestUpdate": "最近修改",
  "aiRecoModel.editorName": "修改人",
  "aiRecoModel.status": "在线状态",
  "aiRecoModel.inputPlaceholder": "模型名称，模型ID",
  "aiRecoModel.baseInfo": "基础信息",
  "aiRecoModel.maxNumber": "最大推荐数量",
  "aiRecoModel.parameters": "模型参数",
  "aiRecoModel.model_parameter_opti": "模型参数优化",
  "aiRecoModel.userAndPetInfo": "用户和宠物信息",
  "aiRecoModel.viewHistory": "查看历史",
  "aiRecoModel.buyHistory": "购买历史",
  "aiRecoModel.nutritionBenchmark": "营养基线",
  "aiRecoModel.foodDatabase": "食物数据库",
  "aiRecoModel.nutritionBenchmark2": "营养基线",
  "aiRecoModel.foodDatabase2": "食物数据库",
  "aiRecoModel.cancel": "取消",
  "aiRecoModel.apply": "应用",
  "aiRecoModel.userInfo": "用户信息",
  "aiRecoModel.petInfo": "宠物信息",
  "aiRecoModel.lastWeek": "上周",
  "aiRecoModel.lastMonth": "上月",
  "aiRecoModel.lastThreeMonths": "近3月",
  "aiRecoModel.lastYear": "去年",
  "aiRecoModel.NRC": "国家研究委员会 (NRC)",
  "aiRecoModel.AAFCO": "美国饲料控制官员协会 (AAFCO)",
  'aiRecoModel.FEDIAF': '欧洲宠物食品工业联合会 (FEDIAF)',
  'aiRecoModel.GB': '中华人民共和国国家标准 (GB)',
  "aiRecoModel.foodDatabaseLabel1": "美国农业部 (USDA) 食品数据库",
  "aiRecoModel.foodDatabaseLabel2": "日本食品成分标准表",
  "aiRecoModel.foodDatabaseLabel3": "新西兰食品成分数据库",
  "aiRecoModel.foodDatabaseLabel4": "澳大利亚食品成分数据库",
  'aiRecoModel.drag_to_sort': '拖动排序',
  "aiRecoModel.type.customizedReco": "个性推荐",
  "aiRecoModel.type.relatedReco": "关联推荐",
  "aiRecoModel.type.disable_tip": "您确定要关闭这个AI推荐模型吗？",
  "aiRecoModel.type.enable_tip": "您确定要开启这个AI推荐模型吗？",
  "order.order_total": "总订单",
  "order.order_status": "订单装填",
  "order.products": "商品",
  "order.orderId": "订单号",
  "order.subscriptionId": "订阅号",
  "order.variation": "维度",
  "order.viewDetail": "查看订单详情",
  "order.arrangeShipment": "安排发货",
  "order.completed": "已完成",
  "order.orderCompleted": "订单已完成",
  "order.completeTip": "您确定要将订单设置为已完成状态吗？",
  "order.carrierCompany": "物流公司",
  "order.carrierCompanyTip": "请选择物流公司",
  "order.carrierNumber": "快递单号",
  "order.carrierNumberTip": "请输入快递单号",
  "order.shipmentDate": "发货日期",
  "order.shipmentDateTip": "请选择发货日期",
  "order.view_carrier_detail": "查看物流详情",
  "order.carrier_info": "物流信息",
  "order.status.completed": "订单已完成",
  "order.status.ship": "订单已发货",
  "order.status.pay": "支付成功",
  "order.status.cancel": "订单已取消",
  "order.status.init": "订单已创建",
  "order.payment": "支付信息",
  "order.payment_method": "支付方式",
  "order.transaction_id": "交易号",
  "order.payment_time": "支付时间",
  "order.order_info": "订单信息",
  "order.view_amount_detail": "查看订单价格",
  "order.product_amount": "商品价格",
  "order.promotion_amount": "促销价格",
  "order.shipping_fee": "运费",
  "order.order_amount": "订单总额",
  "order.status.tab.all": "全部",
  "order.status.tab.unpaid": "未支付",
  "order.status.tab.to_ship": "待发货",
  "order.status.tab.shipped": "已发货",
  "order.status.tab.completed": "已完成",
  "order.status.tab.canceled": "已取消",
  "order.orders": "订单",
  "order.export.export": "导出",
  "order.export.report_name": "报表时间",
  "order.export.latest_reports": "最近一次报表",
  "order.export.download_tip": "这些是未下载的报表",
  "order.export.view_all": "查看所有",
  "order.export.my_exports": "我的报表",
  "order.create_date": "订单创建日期",
  "order.pet_owner_name": "Pet Owner名称",
  "order.order_type": "订单类型",
  "order.carrier.all": "所有物流公司",
  "order.carrier.sf": "SF快递",
  "order.carrier.sto": "申通快递",
  "order.carrier.yto": "圆通快递",
  "order.carrier.zto": "中通快递",
  "order.carrier.best": "百世快递",
  "order.carrier.yunda": "韵达快递",
  "order.type.single": "一次性购买",
  "order.type.subscription": "订阅",
  "order.setting.general": "通用订单设置",
  "order.setting.tip_1": "在",
  "order.setting.tip_2": "分钟后订单未支付，系统会自动关闭订单。",
  "order.setting.tip_3": "天后订单发货未完成，系统会自动完成订单。",
  "comment.delete_comment": "删除评论",
  "comment.are_you_sure_to_delete": "您确定要删除该条评论吗？",
  "operation.by": "By",
  "payment.supported_payment": "支持的支付方式",
  "payment.supported_tip": "支付机构提供的且被DTC Center审核通过的支付方式。",
  "payment.provider": "提供方",
  "payment.status": "状态",
  "payment.merchant_account": "商户账号",
  "payment.certificate_number": "证书号",
  "payment.private_key": "私钥key",
  "payment.appid": "App ID",
  "payment.url": "URL",
  "payment.api_v3_key": "API_V3_KEY",
  "payment.public_key": "公钥key",
  "petowner.profile_photo": "相片",
  "petowner.pet_name": "宠物昵称",
  "petowner.pet_category": "宠物类别",
  "petowner.gender": "性别",
  "petowner.breed": "品种",
  "petowner.birth_date": "生日",
  "petowner.age": "年龄",
  "petowner.months": "月",
  "petowner.sterillized_status": "绝育状态",
  "petowner.sterillized": "已绝育",
  "petowner.unsterillized": "未绝育",
  "petowner.basic_info": "基本信息",
  "petowner.tagging": "标签",
  "petowner.pet_info": "宠物信息",
  "petowner.social_account": "社交平台账号",
  "petowner.order_info": "订单信息",
  "petowner.subscription_info": "订阅信息",
  "petowner.my_address": "地址信息",
  "petowner.voucher_info": "优惠券信息",
  "petowner.address.receiver_name": "收货人",
  "petowner.address.phone_number": "电话号码",
  "petowner.address.province": "省",
  "petowner.address.city": "市",
  "petowner.address.district": "区",
  "petowner.address.address": "详细地址",
  "petowner.address.postal_code": "邮政编码",
  "petowner.wechat_name": "微信昵称",
  "petowner.login_time": "登录时间",
  "petowner.smart_device": "智能设备",
  "petowner.smart_device.name": "智能设备名称",
  "petowner.smart_device.sku": "智能设备SKU",
  "petowner.smart_device.locked_time": "锁定时间",
  "petowner.smart_device.subscription_no": "订阅号",
  "petowner.smart_device.subscription_time": "订阅时间",
  "petowner.unionid": "UnionId",
  "petowner.openid": "OpenId",
  "petowner.user_type": "用户类型",
  "petowner.follow_status": "关注状态",
  "petowner.follow_status.followed": "已关注",
  "petowner.follow_time": "关注时间",
  "petowner.my_pet_owner": "Pet Owner列表",
}
