import React from 'react'

export default {
  // 通用
  "public.ok": "OK",
  "public.confirm": "Confirm",
  "public.cancel": "Cancel",
  "public.new": "New",
  "public.delete": "Delete",
  "public.back": "Back",
  "public.next": "Next",
  "public.Edit": "Edit",
  "public.Detail": "Detail",
  "public.input": "Input",
  "public.select": "Select",
  "public.upload": "Upload",
  "public.file_upload_failed": "file upload failed",
  "public.file_upload_success": "file upload successfully",
  "public.status": "Status",
  "public.action": "Action",
  "public.date": "Date",
  "public.edit": "Edit",
  "public.run": "Run",
  "public.pause": "Pause",
  "public.audit": "Audit",
  "public.delete_item": "Delete Item",
  "public.are_you_sure_delete": "Are you sure you want to delete the item ?",
  "public.delete_description": "Deleted item can’t be recovered.",
  "public.enable_item": "Enable Item",
  "public.disable_item": "Disable Item",
  "public.are_you_sure_enable": "Are you sure you want to enable this item ?",
  "public.are_you_sure_disable": "Are you sure you want to disable this item ?",
  "public.save": "Save",
  "public.confirm_delete": "Confirm Delete",
  "public.operate_success": "Operation success",
  "public.operate_fail": "Operation failure",
  "public.Operation Successful": "Operation Successful",
  "public.search": "Search",
  "public.reset": "Reset",
  "public.add": "Add",
  "public.notice": "Notice",
  "public.yes": "Yes",
  "public.no": "No",
  "public.Export": "Export",
  "public.All": "All",
  "public.enable": "Enable",
  "public.Disable": "Disable",
  "public.Options": "Options",
  "public.option": "Option",
  "public.Operations": "Operations",
  "public.Please Input": "Please Input",
  "public.Please Select": "Please Select",
  "public.field_required": "This field is required",
  "public.deleteItemTip": "Are you sure you want to delete the item?",
  "public.Are you sure you want to restart this subscription?": "Are you sure you want to restart this subscription?",
  "public.Are you sure you want to pause this subscription?": "Are you sure you want to pause this subscription?",
  'public.actions': 'Actions',
  'public.pleaseSelect': 'Please select',
  'public.pleaseInput': 'please input',
  'public.details': 'View Details',
  'public.enableItemTip': 'Are you sure you want to enable the item ?',
  'public.disableItemTip': 'Are you sure you want to disable the item ?',
  "public.no.": "No.",
  "public.download": "Download",
  'public.SKU': 'SKU',
  'public.SPU': 'SPU',
  'public.pleaseEnter': 'Please enter',
  "public.succeed": "Succeed!",
  "public.file_upload_only_jpg": "You can only upload JPG/PNG file!",
  "public.file_upload_smaller_than_2M": "Image must smaller than 2MB!",
  "public.confirm_tip": "Are you sure you want to take this action?",
  "public.No data": "No data.",
  "public.Field": "Field",
  "public.Condition": "Condition",
  "public.Value": "Value",
  "public.AND": "AND",
  "public.OR": "OR",

  // 登录-注册-修改密码
  "login.forget_password": "Forget password?",
  "login.remember_me": "Remember me",
  "login.login": "Log In",
  "login.donot_have_an_account": "Don't have an account?",
  "login.register": "Register",
  "login.account_number": "Account number",
  "login.password": "Password",
  "login.please_input_account_number": "Please input your account number!",
  "login.please_input_password": "Please input your password!",
  "login.user_wrong": "Username or password is wrong!",
  "login.enter_user_name": "Enter user name",
  "login.please_input_username": "Please input your username!",
  "login.enter_phone_number": "Enter phone number",
  "login.please_input_phone": "Please input your phone number!",
  "login.enter_valid_phone": "Enter a valid Phone. example: 13101227768",
  "login.enter_password": "Enter password",
  "login.confirm_password": "Confirm password",
  "login.please_confirm_password": "Please confirm your password!",
  "login.password_should_match": "Two passwords should match!",
  "login.signup": "Sign up",
  "login.verify_sent_to": "Your verification code is sent to",
  "login.select_brand": "Select brand",
  "login.please_select_brand": "Please select brand!",
  "login.select_store": "Select store",
  "login.please_select_store": "Please select store",
  "login.enter_verify_code": "Enter verification code",
  "login.please_enter_code": "Please input validation code!",
  "login.incorrect_code": "Incorrect code!",
  "login.did_your_receive": "Did not receive the code?",
  "login.resend_code": "Resend code",
  "login.resend": "Resend",
  "login.enter_new_password": "Enter new password",
  "login.phone_not_registed": "Phone is not registed as a user!",
  "login.code_send_failed": "Validation code send failed, please try again!",
  "login.please_select_brand_of_store": "Please select the brand of your store",
  "login.provide_better_service": "Let us provide you with better service",
  "login.please_select_store_of": "Please select a store of",
  "login.expired_tip": "Login expired, please login again!",
  // 头部
  "header.logout": "Logout",
  "header.dtc_center": "DTC Experience",
  "header.efficient_store_tool": "Efficient store management tools",
  "header.back_to_login_page": "Back to Login Page",

  // 菜单
  'menu.messagelist': 'Message List',
  'menu.messageCenter': 'Message Center',
  "menu.dashboard": "Dashboard",
  "menu.aireco": "AIReco",
  "menu.shipment": "Shipment",
  "menu.my_shipment": "My Shipment",
  "menu.shipping_setting": "Shipping Setting",
  "menu.order": "Order",
  "menu.my_orders": "My Orders",
  "menu.order_setting": "Order Setting",
  "menu.subscription": "Subscription",
  "menu.my_subscriptions": "My Subscriptions",
  "menu.product": "Product",
  "menu.my_products": "Product List",
  "menu.add_new_product": "Add New Product",
  "menu.search": "Search",
  "menu.pet_owner": "Consumer",
  "menu.my_pet_owner": "Consumer List",
  "menu.tagging_setting": "Tagging Setting",
  "menu.marketing": "Marketing",
  "menu.marketing_center": "Marketing Center",
  "menu.intelligent_recommendation": "Intelligent Recommendation",
  "menu.finance": "Finance",
  "menu.payment_settings": "Payment Settings",
  "menu.invoice_list": "Invoice List",
  "menu.shop": "Shop",
  "menu.shop_category": "Shop Category",
  "menu.customer_service": "Customer Service",
  "menu.site_map": "Sitemap",
  "menu.social_media": "Sales Channel",
  "menu.channel_options": "Channel Options",
  "menu.wechat_mini_program": "WeChat Mini Program",
  "menu.points_of_sales": "Points of Sales",
  "menu.online_store": "Online Store",
  "menu.account_management": "Account Management",
  "menu.fans_management": "Fans Management",
  "menu.automatic_replies": "Automatic Replies",
  "menu.reply_content": "Reply Content",
  "menu.assets_management": "Assets Management",
  "menu.menu_management": "Menu Management",
  "menu.qr_code_management": "QR Code Management",
  "menu.template_message": "Template Message",
  "menu.mini_program_qr_code": "Mini Program QR Code",
  "menu.mini_program_banner": "Mini Program Banner",
  "menu.recommendation_history": "Recommendation History",
  "menu.description": 'Descriptions',
  "menu.attributes": 'Attributes',
  "menu.filter": 'Filters & Sort',
  "menu.partner": "Partner",
  "menu.partner_type": "Partner Type",
  "menu.partner_list": "Partner List",
  "menu.points": "Points",
  "menu.referrals": "Referrals",
  "menu.loyaltyPrograms": "Loyalty Programs",
  "menu.rewardHistory": 'Reward History',
  'menu.rewardSettings': 'Reward Settings',
  'menu.tax_management': 'Tax Management',
  'menu.tax': 'Tax Setting',
  'menu.quickStart': 'Quick Start',
  'menu.dataManagement': 'Data Management',
  'menu.metaData': 'Meta Data',
  'menu.generalEvents': 'General Events',
  'menu.singleAdd': 'Single Add',
  'menu.bulkAdd': 'Bulk Add',
  'menu.eventAttribute': 'Event Attribute',
  'menu.principalAttribute': 'Principal Attribute',
  'menu.transactionAttribute': 'Transaction Attribute',
  'menu.dataIngestion': 'Data Ingestion',
  'menu.idMapping': 'ID Mapping',
  'menu.events': 'Events',
  'menu.segmentsManagement': 'Segments Management',
  'menu.segmentsList': 'Segments List',
  'menu.addNewSegments': 'Add New Segments',
  'menu.Smart Operation': 'Smart Operation',
  'menu.Process Canvas': 'Process Canvas',
  'menu.Compaign plan': 'Compaign plan',
  'menu.tagManagement': 'Tag Management',
  'menu.tagsList': 'Tags List',
  'menu.templateManagement': 'Templates',
  'menu.emailList': 'Email Template',
  'menu.smsList': 'SMS Template',
  'menu.taskList': 'Task List',
  'menu.Quick Task': 'Quick Task',

  // social netword management
  "wx.account_principal": "Account Principal",
  "wx.please_input_account_principal": "Please input Account Principal!",
  "wx.account_type": "Account Type",
  "wx.please_select_account_type": "Please select account type!",
  "wx.original_id": "Original ID",
  "wx.please_input_original_id": "Please input Original ID!",
  "wx.account_name": "Account Name",
  "wx.please_input_account_name": "Please input Account Name!",
  "wx.app_id": "AppId",
  "wx.please_input_appid": "Please input AppID!",
  "wx.merchat_id": "Merchant ID",
  "wx.app_secret": "AppSecret",
  "wx.please_input_app_secret": "Please input AppSecret",
  "wx.merchant_key": "Merchant Key",
  "wx.management_mode": "Management Mode",
  "wx.please_select_mode": "Please select Management Mode!",
  "wx.push_server": "Push Server",
  "wx.official_account_type": "Official Account Type",
  "wx.please_select_official_account_type": "Please select Official Account Type!",
  "wx.message_url": "Message Encryption Url",
  "wx.please_input_message_url": "Please input Message Encryption Url!",
  "wx.token": "Token",
  "wx.please_input_token": "Please input Token!",
  "wx.certificate_path": "Certificate Path",
  "wx.qr_code_path": "QR Code Path",
  "wx.description": "Description",
  "wx.click_to_select": "Click to select",
  "wx.asso_account": "Associated Account",
  "wx.add_account": "Add Account",
  "wx.edit_account": "Edit Account",
  "wx.view_qr_code": "View QR Code",
  "wx.account_enable_tip1": "Are you sure that you want to enable the official account? If yes, the other modules of Wechat Management（Fans managment, Assets management, Medule management...) would only be associated with the activied account.",
  "wx.account_enable_tip2": "Are you sure you want to enable this account?",
  "wx.account_disable_tip1": "Are you sure that you want to disable the official account? If yes, the other modules of Wechat Management（Fans managment, Assets management, Medule management...) would only be associated with the activied account.",
  "wx.account_disable_tip2": "Are you sure you want to disable this account?",
  "wx.add_new_auto_replay": "Add New Automatic Reply",
  "wx.edit_auto_reply": "Edit Automatic Reply",
  "wx.match_type": "Match Type",
  "wx.keywords": "Keywords",
  "wx.please_input_keywords": "Please input keywords!",
  "wx.reply_desc": "Reply Description",
  "wx.please_select_reply_content": "Please select Reply Content!",
  "wx.content_desc": "Content Description",
  "wx.reply_type": "Reply Type",
  "wx.select_reply_content": "Select Reply Content",
  "wx.pls_upload_thumb": "Please upload thumbnail picture!",
  "wx.pls_upload_asso_assets": "Please upload associate asset!",
  "wx.save_and_sync": "Save and Synchronize",
  "wx.add_graphic_message": "Add Graphic Message",
  "wx.title": "Title",
  "wx.pls_input_title": "Please input title!",
  "wx.author": "Author",
  "wx.link": "Link",
  "wx.pls_input_valid_url": "Please input a valid URL!",
  "wx.link_for_article": "Link for \"Read Original Article\"",
  "wx.content": "Content",
  "wx.pls_input_content": "Please input content!",
  "wx.cover_preview": "Cover Preview",
  "wx.suggest_size": "Suggested Size",
  "wx.large_pic_size": "Large Pic: 900*500(Single image or multi-image first article)",
  "wx.small_pic_size": "Small Pic: 200*200(Below the first picture and text of multiple pictures)",
  "wx.abstract": "Abstract",
  "wx.abstract_tip": "Abstrac (optional, if not filled, the first line of 45 words will be grabbed by default)",
  "wx.add_picture_message": "Add Picture Message",
  "wx.add_video_message": "Add Video Message",
  "wx.add_voice_message": "Add Voice Message",
  "wx.single_mess_del_tip": "Single message cannot be deleted!",
  "wx.are_you_sure_to_delete_message": "Are you sure you want to delete this message",
  "wx.new_graphic": "New graphic",
  "wx.select_exist_graphic": "Select an existing graphic",
  "wx.picture_message": "Picture message",
  "wx.voice_message": "Voice message",
  "wx.video_message": "Video message",
  "wx.add_new_message": "Add a new message",
  "wx.picture": "Picture",
  "wx.wechat_assets_link": "Wechat Assets Link",
  "wx.create_time": "Create Time",
  "wx.voice": "Voice",
  "wx.graphic_main_cover": "Graphic main cover",
  "wx.assets_id": "Wechat Assets ID",
  "wx.pictures": "Pictures",
  "wx.video": "Video",
  "wx.videos": "Videos",
  "wx.voices": "Voices",
  "wx.graphic_messages": "Graphic messages",
  "wx.graphic_message": "Graphic message",
  "wx.picture_assets": "Picture Assets",
  "wx.voice_assets": "Voice Assets",
  "wx.video_assets": "Video Assets",
  "wx.add_video": "Add Video",
  "wx.select_file": "Select file",
  "wx.file": "File",
  "wx.pls_upload_file": "Please upload file",
  "wx.pls_input_desc": "Please input description!",
  "wx.confirm_sync": "Confirm Synchronize",
  "wx.are_you_sure_to_sync": "Are you sure you want to synchronize?",
  "wx.main_cover": "Main Cover",
  "wx.view_details": "View Details",
  "wx.publish": "Publish",
  "wx.sync_wechat_assets": "Synchronize WeChat Assets",
  "wx.publish_item": "Publish Item",
  "wx.are_you_sure_to_publish": "Are you sure you want to publish the item?",
  "wx.view_image": "View Image",
  "wx.view_video": "View Video",
  "wx.wechat_account": "Wechat Account",
  "wx.response_type": "Response Type",
  "wx.response_desc": "Response Description",
  "wx.fans_detail": "Fans Detail",
  "wx.wechat_name": "Wechat Name",
  "wx.unionid": "UNIONID",
  "wx.openid": "OPENID",
  "wx.follow_time": "Follow Time",
  "wx.comment": "Comment",
  "wx.channel_qr": "Channel QR code value",
  "wx.is_member": "Is Member",
  "wx.sync_fans_all": "Synchronize All Fans Information",
  "wx.sync_fans_all_con": "Are you sure you want to synchronize ? The number of fans is large, please wait",
  "wx.sync_fans_tip": "The fans information is synchronizing. Once finished, the information will be updated automatically.",
  "wx.sync_fans_openid": "Synchronize All Openids",
  "wx.avatar": "avatar",
  "wx.sync_partial": "Partial Synchronize",
  "wx.sync_partial_tip": "Please select at least one item",
  "wx.sync_all": "Synchronize All",
  "wx.sync_livestream": "Synchronize Live Streaming",
  "wx.all": "All",
  "wx.ongoing": "Ongoing",
  "wx.upcoming": "Upcoming",
  "wx.expired": "Expired",
  "wx.mini_program": "Mini Program",
  "wx.livestream_id": "Live Streaming ID",
  "wx.livestream_name": "Live Streaming Name",
  "wx.anchor_name": "Anchor Name",
  "wx.period": "Period",
  "wx.sync_live_tip": "Please select the live Streaming you want to Synchronize",
  "wx.share": "Share",
  "wx.pls_add_menu": "Please add menu",
  "wx.pls_add_menu_name": "Please add menu name",
  "wx.add_new_menu": "Add New Menu",
  "wx.edit_menu": "Edit Menu",
  "wx.menu_name": "Menu Name",
  "wx.save_and_publish": "Save and Publish",
  "wx.response_should_choose_asset": "Response message menu should choose assets!",
  "wx.redirect_should_contain_url": "Web redirection menu should redirect to a valid url!",
  "wx.miniprogram_should_complete_setting": "Miniprogram menu should complete setting!",
  "wx.menu_valid_fail": "menu item validation failed",
  "wx.gen_menu_data": "Generate menu data",
  "wx.click_to_jump_miniprogram": "Subscribers click on this menu to jump to the following Mini Program",
  "wx.url": "URL",
  "wx.page_path": "Page Path",
  "wx.click_to_jump_link": "Subscribers click on this menu to jump to the following link",
  "wx.page_url": "Page URL",
  "wx.select_from_assets": "Select from assets",
  "wx.menu_item_delete_tip": "Are you sure you want to delete it? The content set under this menu will be deleted after deletion.",
  "wx.move_left": "Move Left",
  "wx.move_right": "Move Right",
  "wx.move_up": "Move Up",
  "wx.move_down": "Move Down",
  "wx.menu_name_length": "No longer than 4 Chinese characters or 8 English characters are recommended",
  "wx.send_response_message": "Send response message",
  "wx.web_redirection": "Web redirection",
  "wx.joint_with_miniprogram": "Joint with Miniprogram",
  "wx.upload": "Upload",
  "wx.update_time": "Update Time",
  "wx.add_new_banner": "Add New MP Banner",
  "wx.edit_banner": "Edit MP Banner",
  "wx.banner_name": "Banner Name",
  "wx.page": "Page",
  "wx.home_page": "Home Page",
  "wx.shop_page": "Shop Page",
  "wx.pic_location": "Pic Location",
  "wx.click_type": "Click Type",
  "wx.no_operation": "No operation",
  "wx.open_web_page": "Open the web page",
  "wx.open_mp_page": "Open the MP page",
  "wx.open_other_mp_page": "Open other MP page",
  "wx.mini_program_id": "Mini Program ID",
  "wx.sort": "Sort",
  "wx.qr_code_detail": "QR Code Detail",
  "wx.add_new_qr_code": "Add New QR Code",
  "wx.channel_type": "Channel Type",
  "wx.qr_code_type": "QR Code Type",
  "wx.qr_code_color": "QR Code Color",
  "wx.scenario_type": "Scenario Type",
  "wx.qr_code_key_value": "QR Code Key Value",
  "wx.mini_program_path": "Mini Program Path",
  "wx.qr_code_size": "QR Code Size",
  "wx.qr_code_size_recom": "Minimun280,Maximun1280",
  "wx.trans_background": "Transparent background",
  "wx.channel": "Channel",
  "wx.scenario": "Scenario",
  "wx.qr_code_value": "QR Code Value",
  "wx.exact_matching": "Exact matching",
  "wx.fuzzy_matching": "Fuzzy matching",

  //dashboard
  "dashboard.shipment": "Shipment",
  "dashboard.shipment_desc": "Provide shipment tool for staff to achieve delivery of orders to consumers.",
  "dashboard.order": "Order",
  "dashboard.order_desc": "Staff can manage order status, query order information, and provide comments for orders.",
  "dashboard.subscription": "Subscription",
  "dashboard.subscription_desc": "Provide subscription tool for staff to query information, modify the delivery time, modify deliver address and add comment of subscription.",
  "dashboard.product": "Product",
  "dashboard.product_desc": "Publish multiple types of products, including bundles and regular, and maintain product categories, product images, attributes, and specifications.",
  "dashboard.marketing": "Marketing Experience",
  "dashboard.marketing_desc": "Provide discounts, vouchers and other marketing tools to help store drainage and drive consumers to re-purchase.",
  "dashboard.channel": "Channel",
  "dashboard.channel_desc": "Support account management, fan management, automatic reply, wechat menu, graphic assets and other functions.",
  "dashboard.solution": "D2C Solution",
  "dashboard.solution_desc": "Solve our clients' toughest challenges by providing unmatched services in strategy, consulting, digital, technology and operations.",
  "dashboard.apidoc": "API Document",
  "dashboard.apidoc_desc": "Contain all the information required to work with the API, with details about the functions, classes, return types, arguments and more.",
  "dashboard.main": "Main Function",
  "dashboard.more": "Explore More",

  //customer service
  "customer.customer_service": "Customer Service",
  "customer.dtc_center_support": "DTC Experience support Controls the enable/disable of the mobile customer service.",
  "customer.mini_program": "Mini program",

  //marketing
  "marketing.recom_basic_setting": "Recommended Basic Settings",
  "marketing.recom_basic_setting_t1": "Product Recommendation Management",
  "marketing.recom_basic_setting_s1": "Manually set weight for products or ban certain products",
  "marketing.recom_strategy_manage": "Recommendation Strategy Management",
  "marketing.recom_strategy_manage_t1": "Top Recommendation",
  "marketing.recom_strategy_manage_s1": "Sorting products according to the overall dimensional statistics, which is usually used in \"Hot Sale\".",
  "marketing.recom_strategy_manage_t2": "Recommendation Based On Product Relativity",
  "marketing.recom_strategy_manage_s2": "Recommend items related to the current item to enhance product co-sale. Commonly found in modules such as \"Related Recommendations\" on the product details page.",
  "marketing.recom_strategy_manage_t3": "Recommendation Based On Consumer Interest",
  "marketing.recom_strategy_manage_s3": "Provide custom recommendation service including recommending products based on user interests, which is often used in modules such as \"you may also like\".",
  "marketing.recom_strategy_manage_t4": "Product finder",
  "marketing.recom_strategy_manage_s4": "Provide custom recommendation service including recommending products based on subscription type and pet information, which is often used in modules such as \"product finder\".",
  "marketing.recom_spot_manage": "Recommended Spot management",
  "marketing.recom_spot_manage_t1": "Shopping Cart",
  "marketing.recom_spot_manage_s1": "Recommendation based on product relativity",
  "marketing.recom_spot_manage_t2": "Product Detail",
  "marketing.recom_spot_manage_s2": "Recommendation based on user interest",
  "marketing.recom_spot_manage_t3": "Product List",
  "marketing.recom_spot_manage_s3": "Top recommendation",
  "marketing.recom_spot_manage_t4": "My Account",
  "marketing.recom_spot_manage_s4": "Recommendation based on user interest",
  "marketing.recom_spot_manage_t5": "Member Experience",
  "marketing.recom_spot_manage_s5": "Recommendation based on user interest",
  "marketing.recom_spot_manage_t6": "Order Confirmation Page",
  "marketing.recom_spot_manage_s6": "Top recommendation",
  "marketing.recom_spot_manage_t7": "Category",
  "marketing.recom_spot_manage_s7": "Recommendation based on product relativity",
  "marketing.recom_spot_manage_t8": "Category Recommendation Channels",
  "marketing.recom_spot_manage_s8": "Recommendation based on user interest",
  "marketing.marketing_center": "Marketing Experience",
  "marketing.boost_sale_promo": "Boost Sales with Promotion",
  "marketing.boost_sale_promo_t1": "Vouchers",
  "marketing.boost_sale_promo_s1": "Increase orders by offering buyers reduced prices at checkout with vouchers",
  "marketing.boost_sale_promo_t2": "Discount Promotions",
  "marketing.boost_sale_promo_s2": "Set discounts on your products to boost Sales",
  "marketing.boost_sale_promo_t3": "Campaigns",
  "marketing.boost_sale_promo_s3": "Boost product sales by creating campaigns in your shop",
  "marketing.engage_petowner": "Engage with Your Consumer",
  "marketing.engage_petowner_t1": "Live Streaming",
  "marketing.engage_petowner_s1": "Connect Live with your audience and answer shopper questions easily",



  // product
  "product.category_cannot_active": "This category cannot be activated as it contains no product",
  "product.create_by": "Created By",
  "product.product": "Product",
  "product.products": "Product(s)",
  "product.product_list": "Product List",
  "product.product_will_add_to_category":
    "If your products meet the filtering rule criteria,they will automatically be added into your shop category",
  "product.set_filter_rule": "Set Filtering Rules",
  "product.edit_filter_rule": "Edit Filtering Rules",
  "product.product_name": "Product Name",
  "product.price": "Price",
  "product.stock": "Stock",
  "product.products_found": "{num} product(s) found",
  "product.product_s": "Products",
  "product.brand": "Brand",
  "product.price(s)": "Price(s)",
  "product.category_name": "Category Name",
  "product.product_category": "Product Category",
  "product.specification": "Specification",
  "product.market_price": "Marketing Price",
  "product.filter_results": "Filtering Results",
  "product.add_products": "Add Products",
  "product.details": "Details",
  "product.my_shop_category": "My Shop Categories",
  "product.add_category": "Add Category",
  "product.category_display_name": "Category Display Name",
  "product.display_on_off": "Display On/Off",
  "product.add_subcategory": "Add subcategory",
  "product.manual_selection": "Manual Selection",
  "product.manual_selection_tip": "Manually select the products you would like to include in your shop category",
  "product.rule_based_filter": "Rule-based Filtering",
  "product.rule_based_tip": "Products will be automatically selected based on the filters you have set up",
  "product.miss_category_name": "Missing Category Name",
  "product.enter_category_name": "Enter a Category Name",
  "product.category_type": "Category Type",
  "product.adjust_seq": "Adjust Sequence",
  "product.miss_display_name": "Missing Display Name",
  "product.enter_display_name": "Enter a Category Display Name",
  "product.search_products": "Search Products",
  "product.products_selected": "{num} products selected",
  "product.category": "Category",
  "product.select_opt_and_change": "Select an option and change input text above",
  "product.select_products": "Select Products",
  "product.Top Search Name": "Top Search Name",
  "product.Priority": "Priority",
  "product.Top Search is visible on shop": "Top Search is visible on shop",
  "product.Add New Top Search": "Add New Top Search",
  "product.To Search Name": "To Search Name",
  "product.Add a New Product": "Add a New Product",
  "product.No category has been chosen": "No category has been chosen",
  "product.Product Type": "Product Type",
  "product.Regular": "Regular",
  "product.Bundle": "Bundle",
  "product.Select a option and change input text above": "Select a option and change input text above",
  "product.Choose Product Type": "Choose Product Type",
  "product.Choose Category": "Choose Category",
  "product.Delete Product": "Delete Product",
  "product.Are you sure want to delete the product(s) ? Warning: You cannot undo this action!": "Are you sure want to delete the product(s) ? Warning: You cannot undo this action!",
  "product.More": "More",
  "product.Products SKUs": "Products SKUs",
  "product.Hide": "Hide",
  "product.Delist": "Delist",
  "product.Publish": "Publish",
  "product.Are you sure want to delete the following product ? Warning: You cannot undo this action!": "Are you sure want to delete the following product ? Warning: You cannot undo this action!",
  "product.Live": 'Live',
  "product.Sold out": 'Sold out',
  "product.Delisted": 'Delisted',
  'product.Variations': 'Variations',
  'product.Variation': "Variation",
  'product.Preview': 'Preview',
  'product.chooseCategoryTip': 'Please choose the right category for your product',
  'product.update': 'Update',
  'product.baseInfo': 'Basic Infomation',
  'product.salesInfo': 'Sales Infomation',
  'product.Shipping': 'Shipping',
  'product.specificationTip': 'Fill in more attributes to boost the exposure of your product.',
  'product.productImage': 'Product Image',
  'product.productImageTip1': 'Every photos should have fine resolution - pixel doesn\'t appear breaking when zooming in',
  'product.productImageTip2': 'Product image can add up to 9 photos',
  'product.productVideo': 'Product Video',
  'product.productVideoTip1': '1. Size: Max 30Mb, resolution should not exceed 1280x1280px',
  'product.productVideoTip2': '2. Duration: 10s-60s',
  'product.productVideoTip3': '3. Format: MP4',
  'product.productVideoTip4': '4. Note: You can publish this listing while the video is being processed. Video \<br /> will be shown in listing once successfully processed.',
  'product.description': 'Product Description',
  'product.spuTip': 'SPU:<p>SPU should be unique and conform to coding rules.</p>',
  'product.productNameTip': 'Product Name:<p>Briefly summarize the product</p>',
  'product.descriptionTip': '`Product Description:\n' +
    '        Should like<br/>\n' +
    '        1. Brand<br/>\n' +
    '        2. Weight<br/>\n' +
    '        3. Benefits<br/>\n' +
    '        4. Feeding advice<br/>\n' +
    '        5. Products Recommended<br/>\n' +
    '        6. Product guarantee<br/>\n' +
    '        `',
  'product.salesStatusTip': 'Sales Status:\n<p>Products that need to be displayed and sold in the store should be set to Saleable</p>\n<p>Products that are not displayed and sold in the mall should be set to Not saleable</p>',
  'product.salesStatus': 'Sales Status',
  'product.Saleable': 'Saleable',
  'product.Not saleable': 'Not saleable',
  'product.productTypeRequired': 'Product Type required',
  'product.productNameRequired': 'Product Name required',
  'product.The currently selected': 'The currently selected',
  'product.Tips': 'Tips',
  'product.Save and Publish': 'Save and Publish',
  'product.Save and Delist': 'Save and Delist',
  'product.zone': 'Zone',
  'product.breeds': 'Breeds',
  'product.Life Stage': 'Life Stage',
  'product.Functions': 'Functions',
  'product.Size': 'Size',
  'product.Technology': 'Technology',
  'product.List Price': 'List Price',
  'product.Subscription Price': 'Subscription Price',
  'product.Feeding Days': 'Feeding Days',
  'product.Support 100': 'Support 100',
  'product.image': 'Image',
  'product.variationImageTip': 'Variation Image:\n  <p>Variation image should be clear and in line with the variation name</p>\n  <p>Image for each of the variations should also be in the same format</p>',
  'product.Live/Dellist': 'Live/Dellist',
  'product.Live/DellistTip': 'Live/Delist:\n' +
    ' <p> Live: SKU on the shelf</p>\n' +
    '  <p>Delist: SKU off the shelf</p>',
  'product.support100Tip': 'Support 100:\n<p>Product production date is set to Y within 100 days, and set to N when not within 100 days</p>',
  'product.feedingDays': 'Feeding Days:\n  <p>Feeding days should be filled reasonably according to the product specifications.</p>',
  'product.stockTip': 'Stock:\n    <p>Number of stocks should reflect the actual stock that is ready to ship.</p>\n    <p>If out of stock, please fill in 0, to avoid non-filfillment rate (NFR) or late shipment rate (LSR).</p>',
  'product.subscriptionPriceTip': 'Subscription Price:\n  <p>Subscription price should include VAT and no more than the market price.</p>\n  <p>Subscription status is N, and the subscription price cannot be entered.</p>',
  'product.marketingPriceTip': 'Marketing Price:\n  <p>Marketing Price should include VAT and remain stable over time.</p>',
  'product.listPriceTip': 'List Price:\n  <p>List Price should include VAT and remain stable over time.</p>',
  'product.SubscriptionTip': 'Subscription:<p>Support subscription should configure Y</p>    \n    <p>Subscription not supported should be N</p>',
  'product.EAN': 'EAN',
  'product.EANTip': 'EAN:\n    <p>EAN should be associated with SKUs and conform to coding rules</p>',
  'product.Sub-SKU': 'Sub-SKU',
  'product.Sub-SKUTip': 'Sub-SKU:<p>Sub-SKU should add the sub-products that the SKU needs to be bound and sold</p>',
  'product.skuName': 'SKU Name',
  'product.skuNameTip': 'SKU Name:\n  <p>SKU Name should be related to the variation option</p>',
  'product.skuTip': 'SKU:<p>SKU should be unique and conform to coding rules</p>',
  'product.variationTip': 'Can set up to two variation. Which the first layer should be more important one',
  'product.name': 'Name',
  'product.addOption': 'Add Option',
  'product.addVariation': 'Add Variation',
  'product.weight': 'Weight',
  'product.weightTip': 'Product Weight:\n<p>Should input the weight when the product is ready to ship, meaning product weight + package weight.</p>',
  'product.parcelSize': 'Parcel Size',
  'product.parcelSizeTip': 'Parcel Size:\n<p>Should input the parcel size not the product size, so that the system can calculate the pick-up car accurately.</p>',
  'product.attributeTip': 'Product Attribute:\n    <p>Product attributes should relate to the product name and description</p>',
  'product.productTag': 'Product Tag',
  'product.productRibbon': 'Product Ribbon',
  'product.Ribbon name': 'Ribbon name',
  'product.Ribbon color': 'Ribbon color',
  'product.Ribbon font': 'Ribbon font',
  'product.Add New Ribbon': 'Add New Ribbon',
  'product.Edit Ribbon': 'Edit Ribbon',
  'product.attribute_label': 'Attribute Label',
  'product.attribute_type': 'Type',
  'product.attribute_display_name': 'Display Name',
  'product.attribute_add_new_title': 'Add a New Attribute',
  'product.attribute_create_tip': 'Please choose the desired type of created attribute',
  'product.attribute_basic_info': 'Attribute Basic Info',
  'product.attribute_required': 'Required',
  'product.attribute_no_options_tip': 'Sorry, there is no options!',
  'product.attribute_guidelines_title': 'Attribute Guidelines',
  'product.attribute_guidelines': 'Attribute guidelines',
  'product.attribute_multi_select': 'Multi SELECT',
  'product.attribute_single_select': 'Single SELECT',
  'product.category_label': 'Category Label',
  'product.category_displayName': 'Display Name',
  'product.category_image': 'Category Image',
  'product.category_description': 'Description',
  'product.category_basic_info': 'Basic Information',
  'product.category_seo_setting': 'SEO Setting',
  'product.category_seo_slug': 'Slug',
  'product.category_seo_title': 'Meta Title',
  'product.category_seo_description': 'Meta Description',
  'product.category_seo_keywords': 'Meta Keywords',
  'product.category_filter': 'Filter',
  'product.category_filter_status': 'Filter Status',
  'product.category_filter_display_name': 'Display Name',
  'product.category_delete_title_tip': 'Are you sure to delete this shop category?',
  'product.category_delete_content_tip': "Deleted shop category can't be recovered.",

  // voucher
  "voucher.orders": "orders",
  "voucher.orders.Search": "Search",
  "voucher.orders.BasicInformation": "Basic Information",
  "voucher.orders.Quantity": "Quantity",
  "voucher.orders.TotalNumberOfVouchers": "Total number of vouchers",
  "voucher.orders.All": "All",
  "voucher.orders.SingleOrder": "Single Order",
  "voucher.orders.NormalSubscription": "Normal Subscription",
  "voucher.orders.ShopVoucher": "Shop Voucher",
  "voucher.orders.ProductVoucher": "Product Voucher",
  "voucher.orders.VoucherName": "Voucher Name",
  "voucher.orders.OrderType": "Order Type",
  "voucher.orders.MinimumBasketPrice": "Minimum Basket Price",
  "voucher.orders.DiscountAmount": "Discount Amount",
  "voucher.orders.Valid Period": "Valid Period",
  "voucher.orders.Voucher Type": "Voucher Type",
  "voucher.orders.Applicable Products": "Applicable Products",
  "voucher.orders.Claimed": "Claimed",
  "voucher.orders.Usage": "Usage",
  "voucher.orders.Products": "Products",
  "voucher.orders.All Products": "All Products",
  "voucher.Unlimited": "Unlimited",
  "voucher.orders.Collapse Voucher Information": "Collapse Voucher Information",
  "voucher.orders.More Voucher Information": "More Voucher Information",
  "voucher.list": "Vouchers List",
  "voucher.list.Data from": "Data from",
  "voucher.list.to": "to",
  "voucher.list.UTC+8": "UTC+8",
  "voucher.list.Status": "Status",
  "voucher.list.Valid Period": "Valid Period",
  "voucher.list.Ongoing": "Ongoing",
  "voucher.list.Upcoming": "Upcoming",
  "voucher.list.Expired": "Expired",
  "voucher.list.Are you sure you want to": "Are you sure you want to",
  "voucher.list.the item?": "the item?",
  "voucher.list.listDescription": "Create and manage your own vouchers for your shop and products on DTC Experience!",
  "voucher.list.Create": "Create",
  "voucher.SKU": "SKU",
  "voucher.SPU": "SPU",
  "voucher.Products": "Products",
  "voucher.Brand": "Brand",
  "voucher.Price(s)": "Price(s)",
  "voucher.Stock": "Stock",
  "voucher.Actions": "Actions",
  "voucher.Product Category:": "Product Category:",
  "voucher.All Category": "All Category",
  "voucher.spu:": "spu:",
  "voucher.Select a option and change input text above": "Select a option and change input text above",
  "voucher.Applicable Products": "Applicable Products",
  "voucher.Add Products": "Add Products",
  "voucher.Product(s) Selected": "Product(s) Selected",
  "voucher.Picture upload failed!": "voucher.Picture upload failed!",
  "voucher.Image must smaller than 1M!": "Image must smaller than 1M!",
  "voucher.Basic Information": "Basic Information",
  "voucher.Shop Voucher": "Shop Voucher",
  "voucher.Product Voucher": "Product Voucher",
  "voucher.Voucher Type": "Voucher Type",
  "voucher.Voucher Name": "Voucher Name",
  "voucher.Voucher Description": "Voucher Description",
  "voucher.Voucher Code": "Voucher Code",
  "voucher.Voucher Value": "Voucher Value",
  "voucher.Valid Period": "Valid Period",
  "voucher.Automatic Offer": "Automatic Offer",
  "voucher.Voucher Image": "Voucher Image",
  "voucher.The recommended size for images is 100px * 100px.": "The recommended size for images is 100px * 100px.",
  "voucher.Image size should not exceed 1M.": "Image size should not exceed 1M.",
  "voucher.Note: One consumer can only use once.": "Note: One consumer can only use once.",
  "voucher.Fix Amount": "Fix Amount",
  "voucher.By Percentage": "By Percentage",
  "voucher.Rule Settings": "Rule Settings",
  "voucher.Please enter a value between 1 and 99": "Please enter a value between 1 and 99",
  "voucher.Discount Type | Amount": "Discount Type | Amount9",
  "voucher.Recurrence": "Recurrence",
  "voucher.Minimum Basket Price": "Minimum Basket Price",
  "voucher.Usage Quantity": "Usage Quantity",
  "voucher.The price cannot be less than the voucher discount amount.": "The price cannot be less than the voucher discount amount.",
  "voucher.Quantity": "Quantity",
  "voucher.Total usable voucher for all consumers": "Total usable voucher for all consumers",
  "voucher.orders.usage.tip": 'Number of vouchers that have been used (excluding cancelled and orders)',
  "voucher.orders.claimed.tip": 'Number of vouchers that have been claimed',
  'voucher.keyMetrics': 'Key Metrics',
  'voucher.GSV': 'GSV',
  'voucher.GSVTip': 'Total amount of all confirmed orders using vouchers.',
  'voucher.order': 'Order',
  'voucher.orderTip': 'Total number of confirmed orders using vouchers.',
  'voucher.usageRate': 'Usage Rate',
  'voucher.usageRateTip': 'Total number of voucher usages in confirmed orders divide by total number of voucher claims.',
  'voucher.petOwners': 'Consumers',
  'voucher.petOwnersTip': 'Total number of unique consumers who used voucher in confirmed orders.',
  'voucher.list.usageLimit': 'Usage Limit',
  'voucher.usageTip': 'Number of vouchers that have been used (excluding cancelled orders)',
  'voucher.Orders': 'Orders',
  'voucher.end': 'End',
  'voucher.selectProduct': 'Select Products',

  // templateMessage
  "templateMessage.View Industries": "View Industries",
  "templateMessage.Synchronous": "Synchronous",
  "templateMessage.Graphical Representation": "Graphical Representation",
  "templateMessage.Add": "Add",
  "templateMessage.Template ID": "Template ID",
  "templateMessage.Please input Template ID": "Please input Template ID",
  "templateMessage.Scenario": "Scenario",
  "templateMessage.Title": "Title",
  "templateMessage.Template Title": "Template Title",
  "templateMessage.Status": "Status",
  "templateMessage.Add New Template": "Add New Template",
  "templateMessage.Select Template": "Select Template",
  "templateMessage.Shipped": "Shipped",
  "templateMessage.Cancel Reminder": "Cancel Reminder",
  "templateMessage.Select Scenario": "Select Scenario",
  "templateMessage.List Representation": "SList Representation",
  "templateMessage.Modify": "Modify",
  "templateMessage.Template ID:": "Template ID:",
  "templateMessage.Template Name:": "Template Name:",
  "templateMessage.Please input Template Name": "Please input Template Name",
  "templateMessage.Select Products": "Select Products",
  "templateMessage.View Industry": "View Industry",
  "templateMessage.Industry": "Industry",
  "templateMessage.Primary Industry": "Primary Industry",
  "templateMessage.Please input Primary Industry": "Please input Primary Industry",
  "templateMessage.Secondary Industry": "Secondary Industry",
  "templateMessage.Please input Secondary Industry": "Please input Secondary Industry",
  "templateMessage.H5 Jump Path": "H5 Jump Path",
  "templateMessage.Please input H5 jump path": "Please input H5 jump path",
  "templateMessage.Mini Program AppId": "Mini Program AppId",
  "templateMessage.Please input Mini Program appid": "Please input Mini Program appid",
  "templateMessage.Jump Path": "Jump Path",
  "templateMessage.Please input Mini Program Jump Path": "Please input Mini Program Jump Path",
  "templateMessage.Description": "Description",
  "templateMessage.Please input Description": "Please input Description",

  // tag
  "tag.Tagging Name:": "Tagging Name:",
  "tag.Pet Owner(s)": "Consumer(s)",
  "tag.Tagging Status": "Tagging Status",
  "tag.Options": "Options",
  "tag.Add New Tag": "Add New Tag",
  "tag.Missing Display Name": "Missing Display Name",
  "tag.Enter a tagging name": "Enter a tagging name",
  "tag.Profile Photo": "Profile Photo",
  "tag.WeChat Name": "WeChat Name",
  "tag.Phone Number": "Phone Number",
  "tag.Pet Owner List": "Consumer List",
  "tag.Add New Pet Owner": "Add New Consumer",
  "tag.Select Pet Owner": "Select Consumer",
  "tag.Price": "Price",
  "tag.Stock": "Stock",
  "tag.Actions": "Actions",
  'tag.tagNameTip': 'Enter tagging name',
  'tag.tagName': 'Tag name',
  'tag.Edit tag': 'Edit tag',
  'tag.delete confirm text': 'Do you confirm to delete this tag?',
  'tag.delete confirm description': 'Deleted tags can’t be recovered',
  'tag.Display in Shop': 'Display in Shop',

  'ribbon.delete confirm text': 'Do you confirm to delete this ribbon?',
  'ribbon.delete confirm description': 'Deleted ribbons can’t be recovered',

  // subscription
  "subscription.Ongoing": "Ongoing",
  "subscription.Paused": "Paused",
  "subscription.Completed": "Completed",
  "subscription.Inactive": "Inactive",
  "subscription": "Subscriptions",
  "subscription.Restart Subscription": "Restart Subscription",
  "subscription.Pause Subscription": "Pause Subscription",
  "subscription.Subscription Creation Date": "Subscription Creation Date",
  "subscription.Subscription ID": "Subscription ID",
  "subscription.Subscription Cycle": "Subscription Cycle",
  "subscription.Order ID": "Order ID",
  "subscription.Product Name": "Product Name",
  "subscription.Phone Number": "Phone Number",
  "subscription.Wechat Name": "Wechat Name",
  "subscription.Actions": "Actions",
  "subscription.Product(s)": "Product(s)",
  "subscription.Subscription Status": "Subscription Status",
  "subscription.Subscription Type": "Subscription Type",
  "subscription.Variation": "Variation",
  "subscription.Restart": "Restart",
  "subscription.Pause": "Pause",
  "subscription.Subscription Time": "Subscription Time",
  "subscription.Pet Detail": "Pet Detail",
  "subscription.Age": "Age",
  "subscription.Breed": "Breed",
  "subscription.Product": "Product",
  "subscription.Unit price": "Unit price",
  "subscription.Quantity": "Quantity",
  "subscription.Freshness": "Freshness",
  "subscription.Subtotal": "Subtotal",
  "subscription.Subscription Product": "Subscription Product",
  "subscription.PauBenefit Namese": "Benefit Name",
  "subscription.Benefit Type": "Benefit Type",
  "subscription.Subscription Benefits": "Subscription Benefits",
  "subscription.View Order Detail": "View Order Detail",
  "subscription.SEQ": "SEQ",
  "subscription.Shipment date": "Shipment date",
  "subscription.Order status": "Order status",
  "subscription.To start": "To start",
  'subscription.info': 'Subscription Information',
  "subscription.auto_ship": "Autoship",
  "subscription.quarter": "Quarter",
  "subscription.half_year": "Half year",
  "subscription.year": "Year",
  "subscription.normal": "Normal",
  "subscription.100": "100",
  "subscription.status.created": "Subscription was created",
  "subscription.status.refill": "The refill order is created",
  "subscription.status.pause": "Subscription was paused",
  "subscription.status.resume": "Subscription was restarted",
  "subscription.status.completed": "Subscription was completed",
  "subscription.log.modify_address": "Modify delivery address",
  "subscription.log.modify_ship_date": "Modify shipment date",
  "subscription.log.create_comment": "Add comment",
  "subscription.log.modify_comment": "Modify comment",
  "subscription.log.delete_comment": "Delete comment",

  // Shipping
  "Shipping.Shipping Setting": "Shipping Setting",
  "Shipping.Express 100": "Express 100",
  "Shipping.Edit Express 100 setting": "Edit Express 100 setting",
  "Shipping.Key:": "Key:",
  "Shipping.Consumer:": "Consumer:",
  "Shipping.Please input consumer": "Please input consumer",
  "Shipping.Pull URL:": "Pull URL:",
  "Shipping.please input user name": "please input user name",
  "Shipping.Query URL:": "Query URL:",
  "Shipping.please input url": "please input url",
  "Shipping.SCallback URL:": "SCallback URL:",
  "Shipping.Please input callbackUrl": "Please input callbackUrl",
  "Shipping.Please input key": "Please input key",
  "Shipping.SEQ": "SEQ",

  // reply
  "reply.Confirm Delete?": "Confirm Delete?",
  "reply.this item?": "this item?",
  "reply.Are you sure you want to": "Are you sure you want to",
  "reply.Reply Type": "Reply Type",
  "reply.Content description": "Content description",
  "reply.Add New Reply Content": "Add New Reply Content",
  "reply.Edit Reply Content": "Edit Reply Content",
  "reply.Please select reply type!": "Please select reply type!",
  "reply.Content Description": "Content Description",
  "reply.Please input description!": "Please input description!",
  "reply.Asset": "Asset",
  "reply.Please select Asset!": "Please select Asset!",
  "reply.Message content": "Message content",
  "reply.Assets Title": "Assets Title",
  "reply.Wechat Assets ID": "Wechat Assets ID",
  "reply.Create Time": "Create Time",
  "reply.Picture": "Picture",
  "reply.Voice": "Voice",
  "reply.Video": "Video",
  "reply.Graphic main cover": "Graphic main cover",
  "reply.Title": "Title",
  "reply.Select Assets": "Select Assets",
  'reply.textMessage': 'Text message',
  'reply.pictureMessage': 'Picture message',
  'reply.voiceMessage': 'Voice message',
  'reply.videoMessage': 'Video message',
  'reply.graphicMessage': 'Graphic message',

  // qrCode
  "qrCode.Temporary integer parameter": "Temporary integer parameter",
  "qrCode.Temporary string parameter": "Temporary string parameter",
  "qrCode.Permanent integer parameter": "Permanent integer parameter",
  "qrCode.Permanent string parameter": "Permanent string parameter",
  "qrCode.QR Code Name": "QR Code Name",
  "qrCode.Expired Time": "Expired Time",
  "qrCode.QR Code Type": "QR Code Type",
  "qrCode.Wechat Account": "Wechat Account",
  "qrCode.Official Account": "Official Account",
  "qrCode.Add QR Code": "Add QR Code",
  "qrCode.Please input QR Code Name!": "Please input QR Code Name!",
  "qrCode.Please select QR Code Type!": "Please select QR Code Type!",
  "qrCode.Scenario ID": "Scenario ID",
  "qrCode.Please input Scenario ID!": "Please input Scenario ID!",
  "qrCode.Comment": "Comment",
  "qrCode.Scenario STR": "Scenario STR",
  "qrCode.Please input Scenario STR, and do not contain _": "Please input Scenario STR, and do not contain _",
  "qrCode.Response Content": "Response Content",
  "qrCode.Please select Reply Content!": "Please select Reply Content!",
  "qrCode.Mini Program": "Mini Program",
  "qrCode.Banner Name": "Banner Name",
  "qrCode.Pic Location": "Pic Location",
  "qrCode.Click Type": "Click Type",
  "qrCode.Default": "Default",
  "qrCode.Path": "Path",
  "qrCode.Sort": "Sort",

  //Al Reco Models
  "menu.aireco_models": "Al Reco Models",
  "aiRecoModel.Detail": 'Model Detail',
  "aiRecoModel.edit": 'Edit Model',
  'aiRecoModel.list.subTitle': 'The model could be turned on/off and after choosing the recommendation model, the user could edit the parameters of within the model.',
  'aiRecoModel.name': 'Model Name',
  'aiRecoModel.id': 'Model ID',
  'aiRecoModel.aiRecoModel': 'AI Reco Model',
  'aiRecoModel.latestUpdate': 'Latest Update',
  'aiRecoModel.editorName': 'Editor Name',
  'aiRecoModel.status': 'Online Status',
  'aiRecoModel.inputPlaceholder': 'Model Name, Model ID',
  'aiRecoModel.baseInfo': 'Basic Information',
  'aiRecoModel.maxNumber': 'Max Rec. Number',
  'aiRecoModel.parameters': 'Model Parameters',
  'aiRecoModel.model_parameter_opti': 'Model Parameter Optimization',
  'aiRecoModel.userAndPetInfo': 'User and Pet Information',
  'aiRecoModel.viewHistory': 'View History',
  'aiRecoModel.buyHistory': 'Buy History',
  'aiRecoModel.nutritionBenchmark': 'Nutrition Benchmark',
  'aiRecoModel.foodDatabase': 'Food Database',
  'aiRecoModel.nutritionBenchmark2': 'Nutrition Benchmark',
  'aiRecoModel.foodDatabase2': 'Food Database',
  'aiRecoModel.cancel': 'Cancel',
  'aiRecoModel.apply': 'Apply',
  'aiRecoModel.userInfo': 'User Information',
  'aiRecoModel.petInfo': 'Pet Information',
  'aiRecoModel.lastWeek': 'Last Week',
  'aiRecoModel.lastMonth': 'Last Month',
  'aiRecoModel.lastThreeMonths': 'Last 3 Months',
  'aiRecoModel.lastYear': 'Last Year',
  'aiRecoModel.NRC': 'National Research Council (NRC)',
  'aiRecoModel.AAFCO': 'Association of American Feed Control Officials (AAFCO)',
  'aiRecoModel.FEDIAF': 'European Pet Food Industry Federation (FEDIAF)',
  'aiRecoModel.GB': 'National Standards of the People\'s Republic of China (GB)',
  'aiRecoModel.foodDatabaseLabel1': 'United States Department of Agriculture (USDA) Food Database',
  'aiRecoModel.foodDatabaseLabel2': 'Standard Tables of Food Composition in Japan',
  'aiRecoModel.foodDatabaseLabel3': 'Food Database of Food Composition in New Zealand',
  'aiRecoModel.foodDatabaseLabel4': 'Food Database of Food Composition in Australia',
  'aiRecoModel.drag_to_sort': 'Drag to sort',
  'aiRecoModel.type.customizedReco': 'Customized Reco',
  'aiRecoModel.type.relatedReco': 'Related Reco',
  'aiRecoModel.type.disable_tip': 'Are you sure you want to disable this ai reco model?',
  'aiRecoModel.type.enable_tip': 'Are you sure you want to enable this ai reco model?',
  "aiRecoModel.history.userName": "User Name",
  "aiRecoModel.history.phone": "Phone",
  "aiRecoModel.history.model": "Model",
  "aiRecoModel.history.modelNameOrId": "Model name/id",
  "aiRecoModel.history.date": "Date",
  "aiRecoModel.history.userSearchingInput": "User Searching Input",
  "aiRecoModel.history.recProductNames": "Rec. Product Name(s)",
  "aiRecoModel.history.skus": "SKU(s)",
  "aiRecoModel.history.recomTime": "Rec. Time",

  //order
  'order.order_total': 'Order Total',
  'order.order_status': 'Order status',
  'order.products': 'Product(s)',
  'order.orderId': 'Order ID',
  'order.subscriptionId': 'Subscription ID',
  'order.variation': 'Variation',
  'order.viewDetail': 'View order details',
  'order.arrangeShipment': 'Arrange shipment',
  'order.completed': 'Completed',
  'order.orderCompleted': 'Order Completed',
  'order.completeTip': 'Are you sure you want to complete the order?',
  'order.carrierCompany': 'Carrier company',
  'order.carrierCompanyTip': 'Please select carrier company',
  'order.carrierNumber': 'Carrier number',
  'order.carrierNumberTip': 'Please input carrier number',
  'order.shipmentDate': 'Shipment Date',
  'order.shipmentDateTip': 'Please select shipment Date',

  "order.view_carrier_detail": "View carrier detail",
  "order.carrier_info": "Carrier information",
  "order.status.completed": "Order was completed",
  "order.status.ship": "Order was shipped",
  "order.status.pay": "Pay successfully",
  "order.status.cancel": "Order was cancelled",
  "order.status.init": "Order was created",
  "order.payment": "Payment",
  "order.payment_method": "Payment method",
  "order.transaction_id": "Transaction id",
  "order.payment_time": "Payment time",
  "order.order_info": "Order Information",
  "order.view_amount_detail": "View order amount detail",
  "order.product_amount": "Products amount",
  "order.promotion_amount": "Promotion amount",
  "order.shipping_fee": "Shipping fee",
  "order.order_amount": "Order amount",
  "order.status.tab.all": "All",
  "order.status.tab.unpaid": "Unpaid",
  "order.status.tab.to_ship": "To ship",
  "order.status.tab.shipped": "Shipped",
  "order.status.tab.completed": "Completed",
  "order.status.tab.canceled": "Cancellation",
  "order.orders": "Orders",
  "order.export.export": "Export",
  "order.export.report_name": "Report name",
  "order.export.latest_reports": "Latest Reports",
  "order.export.download_tip": "Here are the reports you have not downloaded",
  "order.export.view_all": "View all in",
  "order.export.my_exports": "My Reports",
  "order.create_date": "Order Creation Date",
  "order.pet_owner_name": "Consumer Name",
  "order.order_type": "Order Type",
  "order.carrier.all": "All Carrier",
  "order.carrier.sf": "SF Express",
  "order.carrier.sto": "STO Express",
  "order.carrier.yto": "YTO Express",
  "order.carrier.zto": "ZTO Express",
  "order.carrier.best": "BEST Express",
  "order.carrier.yunda": "Yunda Express",
  "order.type.single": "Single Order",
  "order.type.subscription": "Subscription",
  "order.setting.general": "General order setting",
  "order.setting.tip_1": "After",
  "order.setting.tip_2": "minutes,if the PO has not paid successfully,the order would be automatically cancelled",
  "order.setting.tip_3": "days of the order status is shipped,the order would be automatically completed",
  "comment.delete_comment": "Delete Comment",
  "comment.are_you_sure_to_delete": "Are you sure you want to delete this comment?",
  "operation.by": "By",

  "payment.supported_payment": "Supported Payment Method",
  "payment.supported_tip": "A payment method provided by a payment service provider approved by DTC Experience.",
  "payment.provider": "Provider",
  "payment.status": "Status",
  "payment.merchant_account": "Merchat Account",
  "payment.certificate_number": "Certificate Number",
  "payment.private_key": "Private Key",
  "payment.appid": "App ID",
  "payment.url": "URL",
  "payment.api_v3_key": "API_V3_KEY",
  "payment.public_key": "Public Key",

  'comment.remarks': 'Remarks',
  'comment.placeholder': 'Input comment',
  'comment.inputTip': 'Please input your comment',
  'operation.log': 'Operation log',

  'resetPassword.phoneNumber': 'Phone number',
  'resetPassword.resetPassword': 'Reset Password',

  // pet owner
  "petowner.profile_photo": "Profile Photo",
  "petowner.pet_name": "Pet Name",
  "petowner.pet_category": "Pet Category",
  "petowner.gender": "Gender",
  "petowner.breed": "Breed",
  "petowner.birth_date": "Birth Date",
  "petowner.age": "Age",
  "petowner.months": "months",
  "petowner.sterillized_status": "Sterillized Status",
  "petowner.sterillized": "Sterilized",
  "petowner.unsterillized": "Unsterilized",
  "petowner.basic_info": "Basic Information",
  "petowner.tagging": "Tagging",
  "petowner.pet_info": "Pet Information",
  "petowner.social_account": "Social Media Account",
  "petowner.order_info": "Order Information",
  "petowner.subscription_info": "Subscription Information",
  "petowner.my_address": "My Address",
  "petowner.voucher_info": "Voucher Information",
  "petowner.address.receiver_name": "Receiver Name",
  "petowner.address.phone_number": "Phone Number",
  "petowner.address.province": "Province",
  "petowner.address.city": "City",
  "petowner.address.district": "District",
  "petowner.address.address": "Address",
  "petowner.address.postal_code": "Postal Code",
  "petowner.wechat_name": "Name",
  "petowner.login_time": "Login Time",
  "petowner.smart_device": "Smart Device",
  "petowner.smart_device.name": "Smart Device Name",
  "petowner.smart_device.sku": "Smart Device SKU",
  "petowner.smart_device.locked_time": "Locked Time",
  "petowner.smart_device.subscription_no": "Subscription No.",
  "petowner.smart_device.subscription_time": "Subscription Time",
  "petowner.unionid": "UnionId",
  "petowner.openid": "OpenId",
  "petowner.user_type": "User Type",
  "petowner.follow_status": "Follow Status",
  "petowner.follow_status.followed": "Followed",
  "petowner.follow_time": "Followed Time",
  "petowner.my_pet_owner": "Consumer List",
  "petowner.Holding Points": "Holding Points",
  "petowner.Current Tier": "Current Tier",
  "petowner.Tier Start Time": "Tier Start Time",
  "petowner.Tier End Time": "Tier End Time",
  "petowner.Fisrt Order Date": "Fisrt Order Date",
  "petowner.Last Order Date": "Last Order Date",
  "petowner.Points": "Points",
  "petowner.Rewards History": "Rewards History",
  "petowner.Tier Change History": "Tier Change History",
  "petowner.email": 'Email',

  "points.description": "There’s no loyalty program created yet! Please be aware that you need to create a loyalty program firstly and then set up points related rules. The expected creation flow is as below.",
  "points.step1": 'STEP 1',
  "points.step2": 'STEP 2',
  "points.step3": 'STEP 3',
  "points.step4": 'STEP 4',
  "points.step5": 'STEP 5',
  "points.Create Loyalty Program": 'Create Loyalty Program',
  "points.Create Rewards": 'Create Rewards',
  "points.Set up Tiers": 'Set up Tiers',
  "points.Set up Points Rules": 'Set up Points Rules',
  "points.Customized Loyalty Program": 'Go & Experience Your Customized Loyalty Program!',
  "points.Start From Here": 'Start From Here',
  "points.Points Summary": 'Points Summary',
  "points.Points Summary Description": 'See points distributed summary.',
  "points.Total Distributed Points": 'Total Distributed Points',
  "points.Redeemed Points": 'Redeemed Points',
  "points.Expired Points": 'Expired Points',
  "points.Outstanding balance": 'Outstanding balance',
  "points.View and manage all points history": 'View and manage all points history',
  "points.Earn Points": 'Earn Points',
  "points.Earn Points Description": 'Create ways your customer can earn points when they join, share, and engage with your brand.',
  "points.Add ways to earn": 'Add ways to earn',
  "points.Ways to earn": 'Ways to earn',
  "points.Redeem Points": 'Redeem Points',
  "points.Redeem Points Description": 'Create rewards your customer can reward with the points they’ve earned.',
  "points.Add ways to redeem": 'Add ways to redeem',
  "points.Ways to redeem": 'Ways to redeem',
  "points.Points Expiry": 'Points Expiry',
  "points.Points Expiry Description": 'Manage when customers’ points expire. Expiring points is an effective way to re-engage customers who haven’t shopped with you in a while.',
  "points.Points expiry is enabled": 'Points expiry is enabled',
  "points.Points Expiration Term": 'Points Expiration Term',
  "points.Calendar Year": 'Calendar Year',
  "points.Rolling Year": 'Rolling Year',
  "points.Lifetime": 'Lifetime',
  "points.Points": 'Points',

  "referrals.Referral": 'Referral',
  "referrals.Referral rewards": 'Referral rewards',
  "referrals.Referral reward": 'Referral reward',
  "referrals.Referral rewards description1": 'Rewards your existing customers for referring their friends and encouraging them to try out your brand.',
  "referrals.Referral rewards description2": 'The existing customer gets their rewards once the friend makes its first order.',
  "referrals.Referring customer reward": 'Referring customer reward',
  "referrals.Referring customer reward emptyText": 'Add a reward for customers who refer their friends.',
  "referrals.Referred friend reward": 'Referred friend reward',
  "referrals.Referred friend reward emptyText": 'Add a reward for referred friends.',
  "referrals.delete title": 'Are you sure to delete this reward?',
  "referrals.delete description": 'Deleted rewards can’t be recovered.',
  "referrals.Referral setting": 'Referral setting',
  "referrals.Referral setting description": 'Referral setting determines how your referring customer and referred friend to get rewards and other related settings.',
  "referrals.Reward distribution threshold:": 'Reward distribution threshold:',
  "referrals.Registration": 'Registration',
  "referrals.Place an order": 'Place an order',
  "referrals.Minimum order amount": 'Minimum order amount',
  "referrals.Add reward": 'Add reward',


  "loyalPrograms": 'Loyalty Programs',
  "loyalProgram": 'Loyalty Program',
  "loyalPrograms.description": "There’s no loyalty program created yet! Please be aware that a tailed loyalty program is one of the best way to maximize customers’ engagement and increase lifetime value. Follow below steps to create your first loyalty program!",
  "loyalPrograms.step1": 'STEP 1',
  "loyalPrograms.step2": 'STEP 2',
  "loyalPrograms.step3": 'STEP 3',
  "loyalPrograms.step4": 'STEP 4',
  "loyalPrograms.step5": 'STEP 5',
  "loyalPrograms.Create Loyalty Program": 'Create Loyalty Program',
  "loyalPrograms.Create Rewards": 'Create Rewards',
  "loyalPrograms.Set up Tiers": 'Set up Tiers',
  "loyalPrograms.Set up Points Rules": 'Set up Points Rules',
  "loyalPrograms.Customized Loyalty Program": 'Go & Experience Your Customized Loyalty Program!',
  "loyalPrograms.Start From Here": 'Start From Here',
  "loyalPrograms.add": 'Add Program',

  "rewardHistory.consumer_email": 'Consumer Email',
  "rewardHistory.consumer_name": 'Consumer Name',
  "rewardHistory.action": 'Action',
  "rewardHistory.date": 'Date',
  "rewardHistory.points": 'Points',
  "rewardHistory.adjust_points": 'Adjust Points',
  "rewardHistory.adjustment_type": 'Adjustment type',
  "rewardHistory.points_value": 'Points value',
  "rewardHistory.related_transaction": 'Related transaction',
  "rewardHistory.adjustment_reason": 'Adjustment reason',
  "rewardHistory.add": 'add',
  "rewardHistory.minus": 'minus',

  "rewardSettings": 'Reward Settings',
  "rewardSettings.info1": "1. Member tiers relate to member benefits. Members can enjoy the benefits corresponding to the tier.",
  "rewardSettings.info2": "2. Referral rewards are associated with benefits.",
  "rewardSettings.rewards": 'Rewards',
  "rewardSettings.perks": 'Perks',
  "rewardSettings.add_perk": 'Add New Perk',
  "rewardSettings.perk_name": 'Perks name',
  "rewardSettings.reward_name": 'Reward name',
  "rewardSettings.add_reward": 'Add Reward',
  "rewardSettings.add_new_reward": 'Add New Rewards',
  "rewardSettings.free_product": 'Free product',
  "rewardSettings.free_shipping": 'Free shipping',
  "rewardSettings.points": 'Points',
  "rewardSettings.create_perk": 'Create Perk',
  "rewardSettings.create_perk_name": 'Perk name',
  "rewardSettings.create_perk_description": 'Perk description',
  "rewardSettings.reward_history_tittle": 'Reward history title',
  "rewardSettings.create_perk_icon": 'Perk icon',
  "rewardSettings.create_perk_status": 'Status',
  "rewardSettings.create_perk_status_active": 'Active',
  "rewardSettings.create_perk_status_inactive": 'Inactive',
  "rewardSettings.display_name": 'Display name',
  "rewardSettings.description": 'Description',
  "rewardSettings.reward_icon": 'Reward icon',
  "rewardSettings.product_name": 'Product Name',
  "rewardSettings.product_price": 'Product Price',
  "rewardSettings.product_stock": 'Product Stock',
  "rewardSettings.reward_number": 'Reward Number',
  "rewardSettings.add_product": 'Add Product',
  "rewardSettings.free_product_redeem_times": 'Free product redeem times',
  "rewardSettings.reward_value": 'Reward value',
  "rewardSettings.set_shipping_amount": 'Set a minimum shipping amount this reward',
  "rewardSettings.points_value": 'Points value',
  "rewardSettings.choose_a_product": 'Choose a product',
  "rewardSettings.general": 'General',

  "segment.Segments List": 'Segments List',
  "segment.Create New Segments": 'Create New Segments',
  "segment.Segments Detail": 'Segments Detail',
  "segment.Segment Label": 'Segment Label',
  "segment.Segment Description": 'Segment Description',
  "segment.Update Type": 'Update Type',
  "segment.Crowd Number": 'Crowd Number',
  "segment.Last Update Time": 'Last Update Time',
  "segment.Segment Basic Information": 'Segment Basic Information',
  "segment.Segment Rules": 'Segment Rules',
  "segment.Add condition": 'Add condition',
  "segment.Add condition group": 'Add condition group',
  "segment.Crowd Estimation": 'Crowd Estimation',

  "tierManagement": 'Tier Management',
  "pointsSetting": 'Points Setting',
  "referralAFriend": 'Referral a Friend',

  // description
  "description.Description_label": "Description label",
  "description.descriptionLabelTip": "Please enter description label",
  "description.DisplayName": "Display Name",
  "description.DescriptionStatus": "Description Status",
  "description.Action": "Action",
  "description.AddNewDescription": "Add New Description",
  "description.DescriptionType": "Description Type",
  "description.DescriptionDetail": "Description Detail",
  "description.Status": "Status",
  "description.Enable": "Enable",
  "description.Disable": "Disable",
  "description.UnifiedContent": "Unified content",
  "description.CustomizedContent": "Customized content",
  "description.delete_item": "Are you sure to delete this description?",
  "description.deleteItemTip": "Deleted description can't be recovered.",
  "description.confirm": "Yes",
  "description.cancel": "No",

  //filter
  "filter.SelectAttribute": "Select Attribute",
  "filter.SelectTag": "Select Tag",
  "filter.FilterLable": "Filter Lable",
  "filter.DisplayName": "Display Name",
  "filter.AttributeValue": "Attribute Value",
  "filter.TagName": "Tag Name",
  "filter.SortFieldName": "Sort Field Name",
  "filter.SortFieldStatus": "Sort Field Status",

  //Setting Experience---Shipping methods
  'setting.shippingMethod': 'Shipping method',
  'setting.shippingMethod.transitionMethods': 'Transition Methods',
  'setting.shippingMethod.shippingMethods': 'Shipping Methods',
  'setting.shippingMethod.add': 'Add',
  'setting.shippingMethod.transitionMethod': 'Transition Method',
  'setting.shippingMethod.shippingMethod': 'Shipping Method',
  'setting.shippingMethod.status': 'Status',
  'setting.shippingMethod.addShippingMethod': 'Add Shipping Template',

  // uom
  'uom.delete uom': 'Are you sure you want to delete the item ?',

  // dataManagement
  'dataManagement.generalEvents': 'General Events',
  'dataManagement.applicationScenario': 'Application scenario: This module mainly displays, configures (view details or delete), and imports event information in single and batch, including event name, display name, event description, status, so we can track the event in detail in the insight module',
  'dataManagement.eventStatus': 'Event Status',
  'dataManagement.all': 'All',
  'dataManagement.enabled': 'Enabled',
  'dataManagement.disabled': 'Disabled',
  'dataManagement.keywords': 'Keywords',
  'dataManagement.addEvent': 'Add Event',
  'dataManagement.eventName': 'Event Name',
  'dataManagement.displayName': 'Display Name',
  'dataManagement.eventDescription': 'Event Description',
  'dataManagement.actions': 'Actions',
  'dataManagement.options': 'Options',
  'dataManagement.addNewEvent': 'Add New Event',
  'dataManagement.singleAdd': 'Single Add',
  'dataManagement.singleAddDescription': 'Create new events one by one and flexibly modify the display name.',
  'dataManagement.bulkAdd': 'Bulk Add',
  'dataManagement.bulkAddDescription': 'Quickly upload multiple events and batch upload with one button.',
  'dataManagement.attributeName': 'Attribute Name',
  'dataManagement.attributeDescription': 'Attribute Description',
  'dataManagement.dataType': 'Data Type',
  'dataManagement.status': 'Status',
  'dataManagement.eventDetail': 'Event Detail',
  'dataManagement.eventAttribute': 'Event Attribute',
  'dataManagement.eventAttributeDescription': 'Application scenario: This module mainly displays, configures (view details or delete), and imports event attribute information in single and batch, including event attribute name, display name, event attribute description, display status.',
  'dataManagement.associatedEvent': 'Associated Event',
  'dataManagement.associatedEventAttribute': 'Associated Event Attribute',
  'dataManagement.clickDrag': 'Click or drag file to this area to upload',
  'dataManagement.supportFile': '1.Support file format: XLSX, CSV',
  'dataManagement.onlyOneAnd100M': '2.Only one file can be uploaded, and ≤ 100M',
  'dataManagement.downloadTemplate': 'Download Template',
  'dataManagement.eventDetails': 'Event Details',
  'dataManagement.eventInformation': 'Event Information',
  'dataManagement.addEventAttribute': 'Add Event Attribute',
  'dataManagement.addPrincipalAttribute': 'Add Principal Attribute',
  'dataManagement.addTransactionAttribute': 'Add Transaction Attribute',
  'dataManagement.locale': 'Locale',
  'dataManagement.displayStatus': 'Display Status',
  'dataManagement.eventAttributeName': 'Event Attribute Name',
  'dataManagement.description': 'Description',
  'dataManagement.preset': 'Preset',
  'dataManagement.events': 'Events',
  'dataManagement.principalAttribute': 'Principal Attribute',
  'dataManagement.principalAttributeDescription': 'Application scenario: This module mainly displays, configures, and imports event attribute information in batch, including principal attribute name, display name, principal attribute description, display status.',
  'dataManagement.transactionAttribute': 'Transaction Attribute',
  'dataManagement.transactionAttributeDescription': 'Application scenario: This module mainly displays, configures, and imports event attribute information in batch, including transaction attribute name, display name, transaction attribute description, display status.',

  // coming soon
  'Coming soon': 'Coming soon',
  'comingSoon.text': 'We are going to launch our new feature very soon'
}
