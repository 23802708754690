import React, { createContext, useState, useEffect } from 'react'

export const SiderContext = createContext()

const SiderContextProvider = (props) => {
  const [currentWidth, setCurrentWidth] = useState(200)
  const [collapsed, setCollapsed] = useState(false)

  return <SiderContext.Provider value={{ currentWidth, setCurrentWidth,collapsed,setCollapsed }}>{props.children}</SiderContext.Provider>
}

export default SiderContextProvider
