import antd_en_us from 'antd/lib/locale/en_US'
import antd_zh_cn from 'antd/lib/locale/zh_CN'

import loca_en_us from './files/en_US'
import loca_zh_cn from './files/zh_CN'

import promotions_en_us from './primotions/en_US'
import promotions_zh_cn from './primotions/zh_CN'

import smartOperation_en_us from './smartOperation/en_US'
import smartOperation_zh_cn from './smartOperation/zh_CN'

import messageCenter_en_us from './messageCenter/en_US'
import messageCenter_zh_cn from './messageCenter/zh_CN'

// (window as any).langEn = loca_en_us;
// (window as any).langZh = loca_zh_cn;

export type TLangKey = 'en_US' | 'zh_CN';

export default {
  antd: {
    'en_US': antd_en_us,
    'zh_CN': antd_zh_cn,
  },
  loca: {
    'en_US': { ...loca_en_us, ...promotions_en_us, ...smartOperation_en_us, ...messageCenter_en_us },
    'zh_CN': { ...loca_zh_cn, ...promotions_zh_cn, ...smartOperation_zh_cn, ...messageCenter_zh_cn },
  }
}
