import { Suspense, useEffect, useContext } from 'react'
import { useRoutes, useNavigate, useLocation } from 'react-router-dom'
import Router from './routers/index'
import './App.less'
import '@/assets/css/global.less'
import '@/assets/css/iconfont/iconfont.css'
import Loading from '@/assets/images/loading2.gif'
import intl from 'react-intl-universal'
import { ConfigProvider } from 'antd'
import { useAtom } from 'jotai'
import { userAtom } from './store/user.store'
import { langAtom } from './store/lang.store'
import locals, { TLangKey } from './locale'
import { TabContext } from './contexts/TabContext'

import en_US from "antd/lib/locale/en_US"
import classNames from 'classnames'
import React from 'react'

ConfigProvider.config({
  theme: { primaryColor: localStorage.getItem('theme') || '#ee4d2d', },
});




function App() {

  let appClassName = 'consumer-app-container'
  let Routers = useRoutes(Router)
  const navigate = useNavigate()
  const location = useLocation()
  const [userInfo] = useAtom(userAtom)
  const [lang] = useAtom(langAtom)

  // useEffect(() => {
  //   console.log(process.env.REACT_APP_BESE_URL, 'console.log(process.env.REACT_APP_BASE_URL)')
  //   if (location.pathname === '/') {
  //     if (userInfo?.id) {
  //       navigate('/shipment-list')
  //     } else {
  //       navigate('/login')
  //     }
  //   }
  // }, [location.pathname])

  // useEffect(() => {
  //   // url模块跳转, 参数_auth为base64哈希
  //   const paramStr = window.location.search.split('?')[1];
  //   const paramArr = (paramStr ?? '').split('&');
  //   const _auth = paramArr[0].replace('_auth=', '');
  //   if (_auth) {
  //     try {
  //       const _sto = JSON.parse(window.atob(_auth));
  //       Object.keys(_sto).forEach((key: string) => {
  //         localStorage.setItem(key, decodeURIComponent(_sto[key]));
  //       });
  //       console.log('inital local storage:', _sto);
  //       window.location.href = window.location.origin + window.location.pathname;
  //     } catch { }
  //   }
  //   if (!localStorage.getItem('rc_access_token') && !['/login', '/register', '/authorized_error', '/mfa', '/reset', '/resetSuccess'].includes(location.pathname)) {
  //     localStorage.setItem('rc_access_token', '')
  //     localStorage.removeItem('rc-userInfo')
  //     localStorage.removeItem('rc-token')
  //     navigate('/login')
  //   }
  // }, [])

  useEffect(() => {
    if (['/'].includes(location.pathname)) {
      if (process.env.NODE_ENV == 'production') {
        window.location.href = `${process.env.REACT_APP_SELLER_CENTER_DOMAIN}`
      }
    }
  }, [])

  useEffect(() => {
    intl.init({
      //currentLocale: lang,
      currentLocale: 'en_US',
      locales: locals.loca,
      fallbackLocale: 'en_US',
    })
  }, [lang])

  return (
    <div className='App text-center'>
      <ConfigProvider locale={locals['antd'][lang]}>
        <Suspense
          fallback={
            <div className='w-full h-screen flex justify-center items-center'>
              <img className='mb-15' alt='' style={{ width: '100px' }} src={Loading} />
            </div>
          }
        >
          <div className={classNames('text-left', appClassName)}>{Routers}</div>
        </Suspense>
      </ConfigProvider>
    </div>
  )
}

export default App
