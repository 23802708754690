export default {
  'promotions.image size should not exceed 1M.': 'image size should not exceed 1M.',
  'promotions.The recommended size for images is 100px *100px': 'The recommended size for images is 100px *100px',
  'promotions.Add Products': 'Add Products',
  'promotions.Fix Amount': 'Fix Amount',
  'promotions.Unlimited': 'Unlimited',
  'promotions.Usage Quantity': 'Usage Quantity',
  'promotions.Minimum Basket price': 'Minimum Basket price',
  'promotions.Recurrence': 'Recurrence',
  'promotions.Discount Type & Amount': 'Discount Type & Amount',
  'promotions.Order Type': 'Order Type',
  'promotions.Automatic discount': 'Automatic discount',
  'promotions.Promotion image': 'Promotion image',
  'promotions.Promotion Description': 'Promotion Description',
  'promotions.Promotion Title': 'Promotion Title',
  'promotions.Discount Code': 'Discount Code',
  'promotions.Promotion Method': 'Promotion Method',
  "promotions.Free Shipping": "Free Shipping",
  "promotions.Buy X get Y": "Buy X get Y",
  "promotions.Product Discount": "Product Discount",
  "promotions.Order Discount": 'Order Discount',
  "promotions.Applicable Products": "Applicable Products",
  "promotions.Rule Setting": "Rule Setting",
  "promotions.Basic Information": 'Basic Information',
  'promotions.Create New Promotion': 'Create New Promotion',
  'promotions.Discount Type': 'Discount Type',
  'promotions.Discount Amount': 'Discount Amount',
  'promotions.Usage Limit': 'Usage Limit',
  'promotions.Usage': 'Usage',
  'promotions.Status': 'Status',
  'promotions.Actions': 'Actions',
  'promotions.Total amount of all confirmed orders using promotion.': 'Total amount of all confirmed orders using promotion.',
  'promotions.Total number of confirmed orders using promotion.': 'promotions.Total number of confirmed orders using promotion.',
  'promotions.Total number of unique consumers who used promotion in confirmed orders.': 'Total number of unique consumers who used promotion in confirmed orders.',
  'promotions.Please enter promotion type': 'Please enter promotion type',
  'promotions.Promotion Name': 'Promotion Name',
  'promotions.Valid Period': 'Valid Period',
  'promotions.Promotion Type': 'Promotion Type',
  'promotions.All': 'All',
  'promotions.Ongoing': 'Ongoing',
  'promotions.Upcoming': 'Upcoming',
  'promotions.Expired': 'Expired',
  'promotions.Create': 'Create',
  'promotions.GSV': 'GSV',
  'promotions.Order': 'Order',
  'promotions.Consumers': 'Consumers',
  'promotions.Key Metrics': 'Key Metrics',
  'promotions.Date from': 'Data from ',
  'promotions.to': ' to ',
  'promotions.UTC+8': ' UTC+8',
  'promotions.Promotion List': 'Promotion List',
  'Creat and manage your own promotion for your shop and products on Dtc Center': 'Creat and manage your own promotion for your shop and products on Dtc Center'
}