import React, { createContext, useState, useEffect } from 'react'

export const TabContext = createContext()

const TabContextProvider = (props) => {
  const [activeTab, setActiveTab] = useState(0)

  const sendActiveTab = (index) => {
    setActiveTab(index)
    localStorage.setItem('activeTab', index)
  }

  useEffect(() => {
    const tab = localStorage.getItem('activeTab') || 0
    setActiveTab(Number(tab))
  }, [])

  return <TabContext.Provider value={{ activeTab, sendActiveTab }}>{props.children}</TabContext.Provider>
}

export default TabContextProvider
