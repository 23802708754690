/*
 * @Author: yuszhou yuszhou@deloitte.com.cn
 * @Date: 2023-02-21 13:25:18
 * @LastEditors: yuszhou yuszhou@deloitte.com.cn
 * @LastEditTime: 2023-02-21 13:25:19
 * @FilePath: \rc_china_storeportal_ma\src\locale\smartOperation\en_US copy.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {

}