import intl from 'react-intl-universal'
import { Button, Form, Input, message, } from 'antd';
import './index.less'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { authResetPassword } from '@/framework/api/auth';

const Reset = () => {
  const [searchParams] = useSearchParams()
  const navigation = useNavigate()
  const reg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*,\._])[0-9a-zA-Z!@#$%^&*,\\._]{8,16}$/
  const key = searchParams.get('key') || ''
  console.info('keykey', key)
  const onFinish = async (values: any) => {
    if (values.password !== values.password1) {
      // message.error(intl.get('public.operate_fail'))
      message.error('Please enter the same password')
      return
    }
    if (!reg.test(values.password)) {
      message.error('The password must contain uppercase and lowercase letters, special characters, and numbers, with a length of not less than 8 digits and not more than 16 digits')
      return
    }
    const param = {
      key,
      newPassword: values.password
    }
    const res = await authResetPassword(param)
    if (res) {
      message.success(intl.get('public.operate_success'))
      navigation(`/resetSuccess`)
    }
  }

  return (
    <div className='min-h-[100vh] min-w-[100vh] bg-[var(--gray1)]'>
      <div className='flex items-center justify-center min-h-[100vh]  w-[100%]'>
        <div className='w-[594px] h-[506px] bg-white flex items-center justify-center rounded-[10px]' style={{ boxShadow: '1px 4px 12px 1px rgba(0, 0, 0, 0.04);' }}>
          <Form
            name="basic"
            style={{ width: '362px' }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            className='grid gap-[16px] grid-cols-1'
          >
            <div className='text-[24px] font-bold text-center mb-[24px]'>Reset Password</div>
            <Form.Item
              // label="Username"
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' }]}
            >
              <Input.Password className=' h-[40px]'
                // placeholder={intl.get("public.Please Input") || 'public.Please Input'}
                placeholder={intl.get("public.Please Input") || "Please Input"}
              />
            </Form.Item>

            <Form.Item
              // label="Password"
              name="password1"
              rules={[{ required: true, message: 'Please input your Password1!' }]}
            >
              <Input.Password className=' h-[40px]'
                placeholder={intl.get("public.Please Input") || "Please Input"}
              // placeholder={intl.get("public.Please Input") || 'public.Please Input'}
              />
            </Form.Item>


            <Form.Item >
              <Button type="primary" htmlType="submit" className='w-full h-[40px]'>
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Reset
